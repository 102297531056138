export const translationSwedish = {
  /**
   * Header Translations in Swedish by Arash
   */
  NOTIFICATIONS: 'Notifieringar',
  MENU: 'Meny',
  SEARCH: 'Sök',
  MY_PROFILE: 'Mina profiler',
  SETTINGS: 'Inställningar',
  STATISTICS: 'Statistik',
  HELP: 'Hjälp',
  LOG_OUT: 'Logga ut',
  CONNECT_TO_LAB: 'Anslut till labb',

  /**
   * Menu Translations in Swedish
   */
  LIST_VIEW: 'Listvy',
  STATUS_VIEW: 'Statusvy',
  CARD_VIEW: 'Kortvy',
  '1_PATIENT_AND_LAB': '1. PATIENT & LABB',
  '2_CONSTRUCTIONS': '2. KONSTRUKTIONER',
  '3_DELIVERY_INFORMATION': '3. LEVERANSINFORMATION',
  '4_UPLOAD_IMAGE': '4. LADDA UPP BILDER',
  '5_SIGN_AND_SEND': '5. SIGNERA & SKICKA',
  CANCEL_ORDER: 'AVBESTÄLL ORDER',
  ACCEPT_ORDER: 'ORDER MOTTAGET',
  DECLINE_ORDER: 'AVBÖJ ORDER',
  ORDER_ID: 'Order-id',
  RATE_ORDER: 'BETYGSÄTT',
  SEND_ORDER: 'SKICKA ORDER',
  RESEND_ORDER: 'ÅTERSKICKA',
  ARCHIVE_ORDER: 'ARKIVERA',
  UNARCHIVE_ORDER: 'AVARKIVERA',

  '1_PATIENT': '1. PATIENT',
  '3_LAB_AND_DELIVERY': '3. LABB OCH LEVERANS',
  '4_SIGN_AND_SEND': '4. SIGNERA OCH SKICKA',

  ORDER_APPROVED: 'KLART/UTLÄMNAT',

  /**
   * Login Page Translations in Swedish
   */
  EMAIL: 'E-post',
  PASSWORD: 'Lösenord',
  REMEMBER_ME: 'Kom ihåg mig',
  SIGN_IN: 'Logga in',
  FORGOT_THE_PASSWORD: 'Glömt lösenord',
  PASSWORD_RESET_FROM_PROVIDER:
    'Obs! Lösenordsåterställning skickas från vår IT-leverantör Denthub.',

  /**
   * Reset and Forgot password Page Translations in English
   */

  CONFIRM_PASSWORD: 'Bekräfta lösenord',
  RESET: 'Återställ',
  SUBMIT: 'Skicka',
  GO_TO_LOGIN: 'Till inloggning',

  /**
   * New Order Translations in Swedish
   */
  PATIENT_AND_LAB: 'Patient & labb',
  ADD_PATIENT: 'Patientinformation',
  THREE_EASY_STEPS: 'Tre enkla steg',
  PERSONAL_IDENTITY_NUMBER: 'Personnummer',
  FIRST_NAME: 'Förnamn',
  LAST_NAME: 'Efternamn',
  CHOOSE_A_TECHNICIAN_LAB: 'Välj ett labb',
  LAB: 'Labb',
  ORDERS: 'Ordrar',
  CLINICS: 'Kliniker',
  USERS: 'Behandlare',
  LABS_TECHNICIAN: 'Tandtekniker',
  LAB_TECHNICIANS: 'Hantera tekniker',
  CONSTRUCTIONS: 'Konstruktioner',
  FIXED: 'Fasta',
  CROWN: 'Krona',
  SINGLE: 'Singel',
  BRIDGE: 'Bro',
  POST: 'Pelare',
  REMOVABLE: 'Avtagbara',
  IMPLANT: 'Implantat',
  RANGE: 'Länga',
  CLICK_ON_BOTH_ABUTMENT_TEETH_TO_MARK_THE_BRIDGE:
    'Click on both abutment teeth to mark the bridge',
  MATERIAL: 'Material',
  SELECT: 'Välj',
  E_MAX: 'E-Max',
  ACRYLIC: 'Akryl',
  ZIRKONIA: 'Zirkonia',
  EMPRESS: 'Empress',
  TITAN: 'Titan',
  COMPOSITE: 'Komposit',
  ALUMINIUM: 'Aluminum',
  GOLD: 'Guld',
  PORCELAIN: 'Porslin',
  COBALT_CHROME: 'Cobolt Krom',
  OTHER: 'Annat',
  TYPE: 'Typ',
  HERE: 'Här',
  COLOR: 'Färg',
  SHADE: 'Skala',
  COLOR_SAMPLING_AT_LAB: 'Färgtagning på labb',
  METAL: 'Metall',
  PONTIC: 'Pontic',
  DESIGN: 'Design',
  NO_METAL_MARGIN: 'Utan metallkant',
  PORCELAIN_MARGIN: 'Skulderporslin',
  SMALL_METAL_MARGIN: 'Med liten metallkant',
  LINGUAL_METAL_MARGIN: 'Med normal metallkant',
  SANITARY: 'Sanitary',
  BULLET: 'Äggformad',
  MOD_RIDGE_LAP: 'Sadelformad',
  FULL_RIDGE_LAP: 'Full Ridge Lap',
  OVATE: 'Ovate',
  OCCLUSION: 'Ocklusion',
  CONTACT: 'Approximal kontakt',
  HARD: 'Hård',
  NORMAL: 'Normal',
  LIGHT: 'Lätt',
  NONE: 'Ingen',
  REMOVE: 'TA BORT',
  SAVE: 'SPARA',
  DELIVERY: 'Leverans',
  INFORMATION: 'Information',
  DATE: 'Datum',
  CLINIC: 'Klinik',
  ADD: 'Lägg till',
  TYPE_COMMENTS_HERE: 'Skriv kommentarer här',
  UPLOAD: 'Ladda upp',
  IMAGES: 'Bilder',
  FILES: 'Filer',
  CLICK_OR_DRAG_AND_DROP_YOUR_FILE_HERE: 'Klicka eller dra och släpp din fil här',
  SIGN_AND_SEND: 'Signera & Skicka',
  CONFIRM_THE_ORDER: 'Bekräfta ordern',
  YOU_CONFIRM_ALL_INFORMATION_IS_CORRECT:
    'Du bekräftar att all information är korrekt.',
  SAMPLE_DISINFECTED: 'Desinficerat avtryck',
  DROP_IMAGES_HERE_OR_CLICK_TO_UPLOAD:
    'Dra och släpp bilder eller klicka för att ladda upp',
  DROP_FILES_HERE_OR_CLICK_TO_UPLOAD:
    'Dra och släpp filer eller klicka för att ladda upp',
  ORDER_CREATED: 'Order skapad',
  ORDER_MODIFIED: 'Order ändrad',
  ORDER_RESENT: 'Order återskickad',
  SELECT_COLOR: 'Färg',
  SELECT_SHADE: 'Skala',
  CHOOSE_STEP: 'Steg',
  STEP: 'Steg',
  SELECT_MATERIAL: 'Material',
  COMMENTS: 'Kommentarer',
  SELECT_METAl_DESIGN: 'Design',
  SELECT_OCCLUSION: 'Ocklusion',
  SELECT_CONTACT_TYPE: 'Approximal-kontakt',
  CONTACT_TYPE: 'Approximal-kontakt',
  SELECT_PONTIC_DESIGN: 'Pontic-design',
  PLEASE_CONNECT_A_LAB: 'Välj först att ansluta till labb',
  REQUIRED_FIELD: 'Obligatoriskt fält',
  DELIVERY_ADDITIONAL_INFO: 'Leveransinformation',
  ADDITIONAL_INFO: 'Kommentarer',
  CHOOSE_THE_TWO_END_POINTS_OF_THE_BRIDGES: 'Välj start- och sluttand',
  CHOOSE_TEETH_TO_BE_REPLACED: 'Tänder som ska ersättas',
  UPPER_LOWER_JAW_IMAGE: 'Bild på ÖK/UK',
  IMPLANT_STEP: 'Implantat-steg',
  DENTURE_STEP: 'Protes-steg',

  /**
   * New Order 2.0 Translations in English
   */
  PATIENT_INFO: 'Patient',
  PATIENT_CATEGORY: 'Patientkategori',
  SELECT_CATEGORY: 'Välj kategori',
  NEXT_STEP: 'Nästa',
  PREVIOUS_STEP: 'Föregående',
  ORTHODONTICS: 'Ortodonti',
  SNORING_RAILS: 'Snarkskenor',
  BITETRAYS: 'Bettskenor',
  ADD_FILES_OR_IMAGES: 'Lägg till filer och bilder:',
  CHOOSE_LAB_AND_DELIVERY_DETAILS: 'Välj labb- och leveransinformation',
  RANK: 'Rangordning',
  PRICE: 'Pris',
  DELIVERY_LOCATION: 'Leveransplats',
  REASON_FOR_EXCEPTION: 'Anledning till avsteg',
  SELECT_REASON:
    'Välj ett eller flera avsteg eftersom du valt att inte använda det förvalda labbet.',
  DISTANCE: 'Avstånd',
  QUALITY: 'Kvalitet',
  DELIVERY_TIME: 'Leveranstid',
  PATIENT_PREFERENCE: 'Patientens preferens',
  REASON_LANGUAGE: 'Språk',
  ACTIONS: 'Åtgärder',
  CONFIRM_INFORMATION: 'Bekräfta information:',
  PERSONAL_NUMBER: 'Personnummer',
  ACTION: 'Åtgärd',
  CONSTRUCTION_TYPE: 'Konstruktionstyp:',
  LAB_AND_DELIVERY_DETAILS: 'Labb och leveransdetaljer',
  EXCEPTION: 'Avsteg',
  REASON: 'Anledning',
  PRODUCTION_ABROAD: 'Produktion utomlands',
  FILES_AND_IMAGES: 'Filer och bilder',
  BY_SENDING_YOU_CONFIRM:
    'Genom att skicka beställningen bekräftar du att all information är korrekt.',
  NO_OF_PONTIC: 'Antal pontic',
  IMPLANT_STEPS: 'Implantat-steg',
  DENTURE_STEPS: 'Protes-steg',
  OTHER_REMOVABLE: 'Annat',
  TO_HOME_PAGE: 'Tillbaka till orderlistan',
  COMMENT: 'Kommentar',
  BACKGROUND_COLOR_CODE: 'Färgkod bakgrund',
  ENTER_VALID_HEX_BACKGROUND_COLOR_CODE: 'Ange färgkod för bakgrund i hex',
  TEXT_COLOR_CODE: 'Färgkod för text',
  ENTER_VALID_HEX_TEXT_COLOR_CODE: 'Ange färgkod för text i hex',
  SELECT_CLASP_TEETH: 'Klammertänder',
  SELECT_CLASP_MATERIAL: 'Klammermaterial',
  SELECT_FACIAL_LOOP: 'Facialslinga',
  SELECT_CLASP_TYPES: 'Klammertyp',
  SELECT_SPRING_TYPES: 'Fjädertyp',
  SELECT_SPRING_TEETH: 'Fjädertänder',
  ENTER_PROTRUSION_IN_MM: 'Protrusion i mm',

  // Confirm Order Translation in Swedish
  ORDER_NUMBER: 'Ordernummer',
  PATIENT_NAME: 'Patientnamn',
  PRINT_THE_ORDER_AND_FINISH: 'Skriv ut ordern & avsluta',
  MAKE_SURE_YOU_ADD_THIS_PAGE_ON_TOP_OF_THE_BOK_YOU_AR_SENDING_TO_THE_LABORATORY:
    'Kom ihåg att lägga utskriften i lådan som du skickar till labbet.',
  THANK_YOU_FOR_YOUR_ORDER: 'Tack för din order',

  // Order List Translation in Swedish
  PATIENT: 'Patienter',
  DENTIST: 'Behandlare',
  STATUS: 'Status',
  DELIVERY_DATE: 'Leveransdatum',
  ADD_USER: 'LÄGG TILL BEHANDLARE',
  ADD_CLINIC: 'LÄGG TILL KLINIK',
  SENT_BY_DENTIST: 'SKICKAT AV TANDLÄKARE',
  ACCEPTED_BY_LAB: 'MOTTAGET AV LABB',
  ACCEPTED_BY_LAB_BUTTON: 'TA EMOT ORDER',
  DECLINE_BY_LAB: 'AVBÖJT AV LABB',
  SENT_BY_LAB: 'SKICKAT AV LABB',
  SENT_BY_LAB_BUTTON: 'SKICKA TILL KLINIK',
  DECLINED: 'AVBÖJT',
  GET: 'HÄMTA',

  /**
   * User Module Translations in Swedish
   */
  USER_STATUS_UPDATED: 'Användarstatus uppdaterad',
  USER_CREATED: 'Behandlare skapad',
  ADD_NEW_USER: 'Lägg till ny behandlare',
  NAME: 'Namn',
  USER_NAME: 'Användarnamn',
  USER_EMAIL: 'E-post',
  MAKE_ADMIN: ' Gör till administratör',
  CHOOSE_CLINICS: 'Välj kliniker',
  CANCEL: 'Stäng',
  ACTIVE_USER: 'Aktiv',
  INACTIVE_USER: 'Inaktiv',
  EDIT: 'Ändra',
  UPDATE_USER: 'Uppdatera behandlare',
  ROLE: 'Roll',

  /**
   * Clinic Module Translations in Swedish
   */
  PARENT: 'Huvud',
  PARENT_NAME: 'Namn h. klinik',
  UPDATE_CLINIC: 'Uppdatera klinik',
  DELIVERY_ADDRESS: 'Leveransadress',
  INVOICE_ADDRESS: 'Fakturaadress',
  STREET: 'Gata',
  POSTCODE: 'Postnummer',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Är du säker att vill ta bort?',
  CITY: 'Postort',
  ACTIVE_CLINIC: 'Aktiv klinik',
  INACTIVE_CLINIC: 'Inaktiv klinik',
  ACTIVE: 'Aktiv',
  INACTIVE: 'Inaktiv',
  ACTIVATE: 'Aktivera',
  DELETE: 'Ta bort',
  SUCCESSFULLY_UPDATED: 'Uppdatering lyckades',
  PLEASE_ENTER_VALID_PINCODE: 'Ange rätt postnummer',
  SUCCESSFULLY_ADDED: 'Tillagd!',
  SUCCESSFULLY_DELETED: 'Bortaget!',
  CLINIC_IMAGE: 'Klinikbild',
  COST_CENTER: 'Ref/ Po/ Cost center',
  TELEPHONE: 'Telefonnummer',
  EXTERNAL_INFO: 'External info',
  EXTERNAL_ID_1: 'External id 1',
  EXTERNAL_ID_2: 'External id 2',

  /**
   * Lab Module Translations in Swedish
   */
  UPDATE_LAB: 'Uppdatera labb',
  ACTIVE_LAB: 'Aktiva labb',
  INACTIVE_LAB: 'Inaktiva labb',
  LAB_IMAGE: 'Labb-bild',
  ADD_LAB: 'Lägg till labb',
  CONFIRM: 'bekräfta',
  LABS: 'Labb',
  INVALID_PIN_CODE: 'Fel postnummer',

  /**
   * Lab-Technician Module Translations in Swedish
   */
  UPDATE_LAB_TECHNICIAN: 'Uppdatera tandtekniker',
  ADD_LAB_TECHNICIAN: 'Lägg till tandtekniker',
  TECHNICIAN: 'Tekniker',
  UPDATE: 'Uppdatera',
  CHOOSE_LABS: 'Välj labb',
  LAB_TECHNICIAN_CREATED: 'Tandtekniker skapad ',
  LAB_TECHNICIAN_UPDATED: 'Tandtekniker uppdaterad ',
  LAB_TECHNICIAN_STATUS_UPDATED: 'Status för tandtekniker uppdaterad ',
  GENERAL_INFORMATION: 'Generell information',
  SHORT_DESCRIPTION: 'Kort information',
  LONG_DESCRIPTION: 'Utökad information',
  SHORT_DESCRIPTION_MESSAGE: 'Denna beskrivning kommer visas i listvyn',
  LONG_DESCRIPTION_MESSAGE:
    'Denna beskrivning kommer visas i sidan för labbprofilen',
  LAB_NAME: 'Labbnamn',
  CURRENT_JOBS: 'Beställningsbara konstruktioner',
  SINGLE_CROWN: 'Singelkrona',
  IMPLANT_CROWN: 'Implantatkrona',
  IMPLANT_BRIDGE: 'Implantatbro',
  FULL_MAXILLA_DENTURE: 'Helprotes överkäke',
  FULL_MANDIBLE_DENTURE: 'Helprotes underkäke',
  PARTIAL_MAXILLA_DENTURE: 'Partialprotes överkäke',
  PARTIAL_MANDIBLE_DENTURE: 'Partialprotes underkäke',
  DAYS_TO_DELIVERY: 'Dagar att leverera',
  DOCUMENTS: 'Dokument',
  MEDIA_FILES: 'Mediefiler',
  LOGO_OF_THE_COMPANY: 'Företagslogo',
  STAFF: 'Personal/Anställda',
  CHANGE_PICTURE: 'Byt bild',
  OPENING_HOURS: 'Öppettider',
  MONDAY: 'Måndag',
  TUESDAY: 'Tisdag',
  WEDNESDAY: 'Onsdag',
  THURSDAY: 'Torsdag',
  FRIDAY: 'Fredag',
  SATURDAY: 'Lördag',
  SUNDAY: 'Söndag',
  TO: 'Till',
  FROM: 'Från',
  CONTACT_INFORMATION: 'Kontaktinformation',
  SELECT_ALL_DAYS: 'Välj alla dagar',
  PLEASE_ENTER_EMAIL: 'Ange e-post',
  PLEASE_ENTER_PHONE_NUMBER: 'Ange telefonnummer',
  PLEASE_ENTER_ADDRESS: 'Ange adress',
  POSTAL_CODE: 'Postnummer',
  PLEASE_ENTER_CITY_NAME: 'Ange stad',
  SITE_LINK: 'Hemsida',
  UPLOAD_FILE: 'Ladda upp fil',

  SOMETHING_WENT_WRONG: 'Något gick fel!',
  INFO: 'Info',
  DAYS_TO_DELIVERY_INFO:
    'Från och med dagen avtrycket tas emot och tills det är levererat till behandlare (inklusive transport)',

  /**
   * order Module Translations in Swedish
   */
  CURRENT: 'Aktuella',
  DELIVERED: 'Levererad',
  IMAGE: 'bild',
  DRAG_AND_DROP_HERE: 'eller dra & släpp här',
  BY: 'av',
  SUCCESS_UPLOAD: 'Uppdaterat',
  TYPE_YOUR_MESSAGE: 'Skriv ditt meddelande här...',
  ADDITIONAL_ORDER_INFORMATION: 'Övrig orderinformation',

  /**
   * Cancel Order Translations
   */
  CANCEL_THE_ORDER: 'Avbeställ ordern',
  WHY_ARE_YOU_CANCELLING_THE_ORDER: 'Varför vill du avbeställa ordern?',
  ADD_AN_EXPLANATION_FOR_THE_LAB: 'Skriv en anledning till labbet här',
  WHEN_YOU_CANCEL_THE_ORDER_ALL_INFORMATION_WILL_BE_DELETED:
    'När du avbeställer ordern kommer du inte kunna ändra något mer i ordern.',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_THE_ORDER:
    'Är du säker på att du vill avbeställa ordern?',
  NO_CANCEL_THE_ORDER: ' Nej, avbeställ inte ordern',
  YES_CANCEL_THE_ORDER: 'Ja, avbeställ ordern',

  /**
   * Decline order Translations
   */
  DECLINE_THE_ORDER: 'Avböj ordern',
  WHY_ARE_YOU_DECLINING_THE_ORDER: 'Varför vill du avböja ordern?',
  ADD_AN_EXPLANATION: 'Lägg till anledning',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_DECLINE_THE_ORDER:
    'Är du säker att du vill avböja ordern?',
  NO_DECLINE_THE_ORDER: ' Nej, avböj inte ordern',
  YES_DECLINE_THE_ORDER: 'Ja, avböj ordern',

  /**
   * Accept the order
   */
  ACCEPT_THE_ORDER: 'Order mottagen',

  /**
   * Rate Order Modal translations in Swedish
   */
  SAVE_RATINGS: 'Spara betyg',
  RATE_TEXT: 'Betygsätt hur nöjd du är med ordern',
  MIN_AND_MAX_STARS: ' (5 stjärnor högsta , 1 stjärna lägsta)',
  PROTHESES: 'Proteser',
  BITE_SPLINTS: 'Bite Splints',
  FIT: 'Passning',
  AESTHETIC: 'Estetik',
  BRIDGES: 'Broar',
  CROWNS: 'Kronor',
  POSTS: 'Pelare',
  /**
   * Resend the order
   */
  RESEND_ORDER_SUCCESS: 'Ordern har återskickats',

  /**
   * Modify the order
   */
  MODIFY_ORDER_SUCCESS: 'Ordern ändrades.',

  /**
   * Send order Translations
   */
  SEND_THE_ORDER: 'Godkänn och skicka ordern',
  ARCHIVE_THE_ORDER: 'Arkivera ordern',
  UNARCHIVE_THE_ORDER: 'Av-arkivera ordern',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_THE_ORDER_STATUS_TO:
    'Är du säker på att du vill ändra orderstatus till ',
  LAB_APPROVAL_CONFIRMATION_TEXT:
    'Är du säker på att du vill godkänna ordern occh ändra status till',
  APPROVED_BY: 'Arbetet är godkänt för leverans av',
  APPROVED_FOR_DELIVERY: 'Godkänt för leverans av',
  APPROVED_BY_SHORT: 'Godkänd av',
  APPROVE_ORDER: 'Godkänn & skicka',
  APPROVED_BY_INSTRUCTIONS:
    'Det kan även skriva ditt namn manuellt om du saknar en användare i listan.',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_ARCHIVE_THE_ORDER:
    'Är du säker på att du vill arkivera ordern',
  THE_ORDER_WILL_BE_ARCHIVED_FOR_EVERYONE_IN_THE_SAME_ORGANIZATION:
    'Ordern kommer att arkiveras för alla användare i organisationen.',
  ARCHIVE_THE_ORDER_ONLY_FOR_ME: 'Arkivera enbart för mig',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_UNARCHIVE_THE_ORDER:
    'Är du säker att du vill av-arkivera ordern',
  NO_CHANGE_THE_STATUS: ' Nej, ändra inte orderstatus',
  NO_ARCHIVE_THE_ORDER: 'Nej, arkivera inte ordern',
  NO_UNARCHIVE_THE_ORDER: 'Nej, avarkivera inte ordern',
  YES_CHANGE_THE_STATUS: 'Ja, ändra orderstatus',
  YES_ARCHIVE_THE_ORDER: 'Ja, arkivera ordern',
  YES_UNARCHIVE_THE_ORDER: 'Ja, avarkivera ordern',

  ALREADY_DECLINED: 'Redan avböjt',
  FILE: 'fil',
  ACCESS_DENIED: 'Tillgång nekad',
  SEND: 'Skicka',

  /**
   * Change Password Module Translations in Swedish
   */
  CHANGE_PASSWORD: 'Byt lösenord',
  TYPE_OLD_PASSWORD: 'Skriv in ditt gamla lösenord',
  TYPE_NEW_PASSWORD: 'Skriv in ditt nya lösenord',
  REPEAT_NEW_PASSWORD: 'Upprepa ditt nya lösenord',
  NEXT: 'nästa',
  OLD_PASSWORD: 'Gamla lösenord',
  NEW_PASSWORD: 'Nytt lösenord',
  REPEAT_NEW_PASSWORD_PLACE: 'Upprepa nytt lösenord',
  PASSWORD_NOT_MATCH: 'Lösenord matchar inte',
  PASSWORD_SUCCESSFULLY_UPDATED: 'Lösenordet har ändrats',
  IMAGE_SUCCESSFULLY_UPLOADED: 'Bild har laddats upp',
  FILE_UPLOADED: 'Fil har laddats upp',
  FILE_REMOVED: 'Fil borttaget',
  OR_DRAG_AND_DROP_HERE: 'eller dra & släpp här',

  /**
   *  Dentist Setting module translation in english
   */
  PROFILE: 'Profil',
  ACCOUNT: 'Konto',
  SYSTEM: 'System',
  SYSTEM_SETTINGS: 'Systeminställningar',
  CONSTRUCTION: 'Konstruktion',
  CONSTRUCTION_SETTINGS: 'Förvalda konstruktionsegenskaper',
  DEFAULT_CLINIC: 'Standardklinik',
  PLEASE: 'Var god',
  LANGUAGE: 'Språk',
  SAVE_CHANGES: 'Spara ändringar',
  INSERT_MESSAGE_PLEASE: 'Lägg till meddelande först.',
  ENGLISH: 'English',
  SWEDEN: 'Svenska',

  /**
   * Connect to lab translation in Swedish
   */
  SEARCH_LAB: 'Sök labb',
  DESCRIPTION: 'Beskrivning',
  SHOW_ALL_LABS: 'VISA ALLA LABB',
  FIND_A_LAB: 'Hitta ett labb',
  FIND_LAB_SEARCH_PLACEHOLDER: 'Skriv in namn eller stad...',
  FIND_LAB_TEXT_FIRST: 'Sök efter labb efter namn eller stad. Eller klicka',
  FIND_LAB_TEXT_SECOND: ' för att se vilka du kan ansluta mot.',
  DESCRIPTION_OF_THE_MESSAGE: 'Beskrivning av labbet',
  DOWNLOAD: 'Ladda ned',
  OPENING_DAYS: 'Arbetsdagar',
  MON: 'Mån',
  TUE: 'Tis',
  WED: 'Ons',
  THU: 'Tor',
  FRI: 'Fre',
  SAT: 'Lör',
  SUN: 'Sön',
  LAB_DIS_CONNECTED: 'Ej ansluten',
  LAB_CONNECTED: 'Ansluten',
  CONNECT: 'Anslut',
  DISCONNECT: 'Koppla från',
  DISCONNECT_TO_LAB: 'Koppla från',
  LAB_PROFILE: 'LABBPROFIL',
  NO_LABS_FOUND: 'Inga labb hittades!',

  /**
   * Some Other translations in Swedish related to menu
   */
  SUPER_ADMIN: 'SUPER ADMIN',
  CLINIC_ADMIN: 'KLINIK ADMIN',
  LAB_ADMIN: 'LABB ADMIN',
  ALL_ORDERS: 'Alla ordrar',
  MY_SETTINGS: 'Mina inställningar',
  MANAGE_USERS: 'Hantera behandlare',
  EDIT_LAB: 'Ändra labb',
  MANAGE_CLINICS: 'Hantera kliniker',
  MANAGE_LABS: 'Hantera labb',

  /**
   * Early Delivery Status and Message in Swedish
   */
  EARLY_DELIVERY_WARNING_TEXT:
    'OBS! Valt datum avviker från labbets önskade leveranstid.',
  EARLY_DELIVERY: 'Tidig leverans!',
  BACK_TO_SUPER_ADMIN: 'Tillbaka till Super Admin',

  /**
   * Notifications Module Translations in Swedish
   */
  IS_ACCEPTED_BY_LAB: 'är mottaget av labb.',
  IS_SENT_BY_LAB: 'är skickat av labb.',
  IS_CANCELLED: 'är avbruten.',
  IS_DECLINED: 'är avböjd.',
  NEW_ORDER_ARRIVED: 'Ny order har inkommit.',
  NEW_ORDER_NOTIFICATION_PREFIX: 'Ny order',
  RECEIVED_NOTIFICATION_SUFFIX: 'har inkommit.',
  YOU_HAVE_A_NEW_MESSAGE_IN_ORDER: 'Du har ett nytt meddelande i order',
  YOU_HAVE: 'du har',
  NEW_MESSAGES_IN_ORDER: ' nytt meddelande i order',
  ORDER: 'Order',
  THERE_ARE_NO_NEW_NOTIFICATIONS: 'Det finns inga nya notifieringar',
  CLEAR_ALL_NOTIFICATIONS: 'Markera alla som lästa',

  /**
   * Removable Constructions Translations in Swedish
   */
  COMPLETE_DENTURE: 'Helprotes',
  PARTIAL_DENTURE: 'Partialprotes',
  TEMPORARY_DENTURE: 'Temporär protes',
  BITETRAY: 'Bettskena',
  VALIO: 'Valplast',
  SOFT: 'Mjuk',
  SOFT_INSIDE: 'Mjuk insida',
  DENTURES: 'Proteser',
  IMMEDIATE_DENTURE: 'Immediatprotes',
  INDIVIDUAL_TRAY: 'Individuell sked',
  BITE_BLOCK: 'Bitschablon',
  DENTITION: 'Tanduppsättning',
  COMPLETE_CONSTRUCTION: 'Färdig konstruktion',

  /**
   * For Constants Translations in Swedish
   */
  PASSWORD_CHANGE_SUCCESS: 'Lösenordsbyte lyckades',
  VISIT_EMAIL_FOR_RESET:
    'Vi har skickat e-post med instruktioner för återställning av lösenord',
  NO_EMAIL_FOUND: 'Ingen e-post hittad',
  METAL_PORCELAIN: 'Metal/Porslin',
  METAL_ACRYLIC: 'Metal/Akryl',
  MBP: 'MBP',
  METAL_AKRYL: 'Metal/Akryl',
  'PFM-GOLD': 'MK-Guld',
  'PFM-TITAN': 'MK-Titan',
  'PFM-COCR': 'MK-CoCr',

  /**
   * Some Other Translations in Swedish
   */
  PARENT_CLINIC: 'Huvudklinik',
  CHOOSE_PARENT_CLINIC: 'Välj huvudklinik',
  NEW_ORDER: 'Ny order',
  NO_RESULT_FOUND: 'Inga resultat hittades',
  SINGLE_CROWNS: 'Singelkronor',
  IMPLANT_BRIDGES: 'Implantatbroar',
  IMPLANT_CROWNS: 'Implantatkronor',
  TOOTH: 'Tand',
  SAMPLING_AT_LAB: 'Provtagning vid labb',
  TEETH: 'Tänder',
  PRINTED_DATE: 'Utskriftsdatum',
  ORDER_CREATED_DATE: 'Order skapad',
  RECEIVING_LAB: 'Mottagande labb',
  FIXED_CONSTRUCTIONS: 'Fasta konstruktioner',
  REMOVABLE_CONSTRUCTIONS: 'Avtagbara konstruktioner',
  CREATED: 'Skapad',
  DELIVERY_DESTINATION: 'Leveransplats',
  CANCELLED: 'Avbruten',
  REJECTED: 'Avvisade',
  PATIENT_INFORMATION: 'Patientinformation',
  PICK_UP_LOCATION: 'Upphämtningsplats',
  PICK_UP_ADDRESS: 'Upphämtningsadress',
  YES: 'Ja',
  NO: 'Nej',
  PONTIC_DESIGN: 'Pontic design',
  METAL_DESIGN: 'Metal design',
  OCCLUSION_CONTACT: 'Ocklusion-kontakt',
  SELECT_OCCLUSION_CONTACT: 'Ocklusion-kontakt',
  SELECT_METAL_DESIGN: 'Metall-design',
  PROFILE_NAME: 'Profilnamn',
  PROFILE_FIRST_NAME: 'Profil förnamn',
  PROFILE_LAST_NAME: 'Profil efternamn',
  PROFILE_EMAIL: 'Profil e-post',
  PLEASE_SELECT_CLINIC: 'Välj klinik',
  SELECT_LANGUAGE: 'Välj språk',
  ACCOUNT_SETTINGS: 'Ditt konto',
  WRITE_A_COMMENT: 'Skriv en kommentar...',
  WRITE_A_COMMENT_FOR_POST_CONSTRUCTION: 'Skriv en kommentar för denna pelare',
  COMMENT_FOR_POST_CONSTRUCTION: 'Kommentar för denna pelare',
  COMMENT_FOR_SINGLE_CROWN_CONSTRUCTION: 'Kommentar för denna singelkrona',
  COMMENT_FOR_IMPLANT_CROWN_CONSTRUCTION: 'Kommentar för denna implantatkrona',
  COMMENT_FOR_BRIDGE_CONSTRUCTION: 'Kommentar för denna bro',
  COMMENT_FOR_IMPLANT_BRIDGE_CONSTRUCTION: 'Kommentar för denna implantatbro',
  COMMENT_FOR_COMPLETE_DENTURE_CONSTRUCTION: 'Kommentar för denna helprotes',
  COMMENT_FOR_BITETRAY_CONSTRUCTION: 'Kommentar för denna bettskena',
  COMMENT_FOR_TEMPORARY_DENTURE_CONSTRUCTION: 'Kommentar för denna temporär protes',
  COMMENT_FOR_PARTIAL_DENTURE_CONSTRUCTION: 'Kommentar för denna partialprotes',
  LAB_CONTACT: 'Labbkontakt',
  COMMENT_FOR_OTHER_CONSTRUCTION: 'Kommentarer för denna konstruktion',
  MODIFY_ORDER: 'ÄNDRA',
  SEE_ALL_ORDERS: 'Se alla ordrar',
  REASON_FOR_CANCELLATION: 'Anledning för avbruten order:',
  REASON_FOR_DECLINE: 'Anledning till avböjt order:',
  CANT_CHANGE_ANY_PROPERTIES: 'Kan inte ändra några egenskaper',
  UPPER_JAW: 'Överkäke',
  LOWER_JAW: 'Underkäke',
  ORGANIZATION_NUMBER: 'Organisationsnummer',
  ENTER_VALID_YEAR: 'Ange ett giltigt datum (YYYYMMDD)',
  VERSION: 'Version',
  DENTHUB: 'DENTHUB',
  LARGE: 'A4',
  MINI: 'KVITTO',
  PRINT_LARGE: 'A4',
  PRINT_MINI: 'Orderkvitto',
  AUTO_ARCHIVE_ORDERS: 'Automatisk arkivering av ordrar',
  DAYS: 'dagar',
  ARCHIVE_MESSAGE:
    'Arkiverar ordrar som har status SKICKAT AV LABB och som ej ändrats inom dessa antal dagar',
  DAYS_NOT_IN_SERVICE_AND_HOLIDAYS: 'Enskilda dagar labbet har stängt',
  MARK_THE_DAYS_THE_LAB_IS_CLOSED_IN_THE_CALENDAR_BELOW:
    'Markera de dagar labbet har stängt. Beställningar kommer tas emot men med senare leveransdatum.',
  SELECT_RETAINMENT: 'Förankring',
  SCREWED: 'Skruvad',
  CEMENTED: 'Cementerad',
  RETAINMENT: 'Förankring',
  FOR_SPECIALIZED_MEDICAL_PRODUCTS_ACCORDING_TO_LVFS:
    'För specialanpassad medicinsk produkt enligt LVFS2003:11',

  // toastr error messages
  ERROR_IN_GETTING_LOGIN_LOGO_IMAGE: 'Fel vid hämtning av logotyp.',
  ERROR_IN_GETTING_APP_VERSION: 'Fel vid hämtning av app version.',
  ERROR_IN_GETTING_USER_DETAILS: 'Fel vid hämtning av användarinfo.',
  ERROR_IN_GETTING_NOTIFICATIONS: 'Fel vid hämtning av notifikationer.',
  ERROR_IN_CHANGE_MESSAGE_STATUS: 'Fel vid ändring av meddelandestatus.',
  ERROR_IN_GETTING_ORDER: 'Fel vid hämtning av order.',
  ERROR_IN_ADD_MESSAGE: 'Fel vid tillägg av meddelande.',
  ERROR_IN_UPDATE_ORDER: 'Fel vid uppdatering av meddelande.',
  ERROR_IN_SAVING: 'Error when saving order.',
  ERROR_IN_UPDATE_USER: 'Fel vid uppdatering av användare.',
  ERROR_IN_GETTING_CLINICS: 'Fel vid hämtning av kliniker.',
  ERROR_IN_GETTING_CLINIC: 'Fel vid hämtning av klinik.',
  ERROR_IN_ARCHIVING_ORDER: 'Fel vid arkivering av order.',
  ERROR_IN_UN_ARCHIVING_ORDER: 'Fel vid avarkivering av order.',
  ERROR_IN_CANCELLING_ORDER: 'Fel vid avbrytning av order.',
  ERROR_IN_APPROVING_ORDER: 'Fel vid godkännande av order.',
  ERROR_IN_CHANGE_PASSWORD: 'Fel vid byte av lösenord.',
  ERROR_IN_UPDATE_CLINIC: 'Fel vid uppdatering av klinik.',
  ERROR_IN_ADD_CLINIC: 'Fel vid tillägg av klinik.',
  ERROR_IN_DELETE_CLINIC: 'Fel vid borttagning av klinik.',
  ERROR_IN_DECLINE_ORDER: 'Fel vid avböjning av order.',
  ERROR_IN_GETTING_LABS: 'Fel vid hämtning av labb.',
  ERROR_IN_LOGIN_AS_SUPER_ADMIN: 'Fel vid inloggning som super admin.',
  ERROR_IN_UPDATE_LAB: 'Fel vid uppdatering av lab.',
  ERROR_IN_ADD_LAB: 'Fel vid tillägg av lab.',
  ERROR_IN_GETTING_LAB_DETAILS: 'Fel vid hämtning av labbinfo.',
  ERROR_IN_CONNECT_LAB: 'Fel vid koppling av labb.',
  ERROR_IN_DISCONNECT_LAB: 'Fel vid koppling ifrån labb.',
  ERROR_IN_GETTING_LAB_RATING: 'Fel vid hämtning av labb-betyg.',
  ERROR_IN_DELETE_LAB: 'Fel vid borttagning av labb.',
  ERROR_IN_UPLOAD_STAFF_IMAGE: 'Fel vid uppladdning av personalbild',
  ERROR_IN_UPLOAD_LOGO_IMAGE: 'Fel vid uppladdning av logotyp-bild.',
  ERROR_IN_UPDATE_LAB_TECHNICIAN: 'Fel vid uppdatering av tandtekniker.',
  ERROR_IN_ADD_LAB_TECHNICIAN: 'Fel vid tillägg av tandtekniker.',
  ERROR_IN_DELETE_LAB_TECHNICIAN: 'Fel vid borttagning av tandtekniker.',
  ERROR_IN_UPLOAD_FILE: 'Fel vid uppladdning av bild.',
  ERROR_IN_DELETE_FILE: 'Fel vid borttagning av bild.',
  ERROR_IN_GETTING_MESSAGES: 'Fel vid hämtning av meddelanden.',
  ERROR_IN_UPDATE_MESSAGE: 'Fel vid uppdatering av meddelanden.',
  ERROR_IN_SEND_MESSAGE: 'Fel när meddelande skickades.',
  ERROR_IN_GETTING_LAB: 'Fel vid hämtning av labb.',
  ERROR_IN_GETTING_ORDERS: 'Fel vid hämtning av ordrar.',
  ERROR_IN_CREATING_ORDER: 'Fel vid skapning av order.',
  ERROR_IN_RATE_ORDER: 'Fel vid betygsättning av order.',
  ERROR_IN_SEARCH_LABS: 'Fel vid sökning av labb.',
  ERROR_IN_GETTING_CLINIC_DETAILS: 'Fel vid hämtning av klinik-info.',
  ERROR_IN_DELETE_USER: 'Fel vid borttagning av användare.',
  ERROR_IN_GETTING_USERS: 'Fel vid hämtning av användare.',
  EMAIL_AND_PASSWORD_NOT_MATCHED: 'Fel e-post eller lösenord!',
  ERROR_IN_SEARCH: 'Fel vid sökning.',
  ERROR_EMAIL_ID_ALREADY_EXIST: 'Email ID already exist',
  RATING: 'Betyg',
  ERROR_IN_UPDATE_USER_PASSWORD: 'Fel vid uppdatering av användarlösenord.',
  PUBLISH_LAB_PROFILE: 'Publicera labbprofil',
  PUBLISH_INFO: 'Publicera labbprofilen på DentHub',
  EXPORT_PRODUCTION: 'Produktion utomlands',
  EXPORT_INFO: 'Tandläkarna får möjlighet att välja produktion utomlands',
  ADDITIONAL_DAYS_TO_DELIVERY: 'Tillägg på leveransdagar',
  PRODUCE_THIS_ORDER_ON_EXPORT: 'Producera denna order utomlands?',
  COCR: 'CoCr',
  DENTITION_WITH_SKELETON: 'Tanduppsättning med skelett',
  SELECT_STEP: 'Välj steg',
  SEND_BY_DENTIST_TO: 'SKICKAT AV TANDLÄKARE till',
  CHANGE_DATE: 'Ändra datum',
  DELIVERY_DATE_CHANGED: 'Leveransdatum har ändrats till:',
  AT: 'på',
  OTHER_MATERIAL: 'Annat material',
  EMAIL_NOTIFICATIONS: 'E-post notifieringar',
  SELECT_WHEN_TO_RECEIVE_EMAIL: 'Välj när e-post skickas',
  SELECT_THE_DESTINATION: 'Välj mottagare',
  WHEN_THE_LAB_CHANGED_THE_DELIVERY_DATE: 'När labbet har ändrat leveransdatum',
  WHEN_A_NEW_ORDER_RECEIVED: 'Ny order mottagen',
  WHEN_A_NEW_MESSAGE_RECEIVED: 'Nytt meddelande mottaget',
  WHEN_AN_ORDER_IS_MODIFIED: 'Order ändrats',
  WHEN_AN_ORDER_IS_RATED: 'Order betygsatts',
  WHEN_AN_ORDER_IS_CANCELLED: 'Order avbeställts',
  WHEN_AN_ORDER_IS_RESEND: 'Order återskickats',
  ENTER_EMAIL_ADDRESS: 'Skriv e-postadress...',
  GENERAL_APPLICATION_NOTIFICATIONS: 'Generella notifieringar i applikationen',
  WHEN_A_NEW_ORDER_IS_RECEIVED: 'Ny order mottaget',
  WHEN_A_MESSAGE_IS_RECEIVED: 'Nytt meddelande mottaget',
  WHEN_THE_ORDER_WAS_MODIFIED: 'Ordern ändrats',
  WHEN_THE_LAB_CHANGE_THE_DELIVERY_DATE: 'Labbet ändrat leveransdatum',
  WHEN_THE_DELIVERY_DATE_HAS_PASSED: 'Leveransdatumet passerat',
  WHEN_THE_ORDER_IS_NOT_RECEIVED_BY_LAB_WITHIN:
    'Order har ej tagits emot labbet inom ',
  VIEW_SETTINGS: 'Vy inställningar',
  INDICATE_IN_RED_WHEN_THE_ORDER_HAS_BEEN_MODIFIED:
    'Indikera med röd text när ordern ändrats',
  INDICATE_IN_RED_WHEN_THE_DELIVERY_DATE_HAS_PASSED:
    'Indikera med röd bakgrund när leveransdatumet passerat',
  DELIVERY_DATE_CHANGES: 'ändringar i leveransdatum',
  IS_NOT_ACCEPTED_BY_LAB: 'ej accepterat av labbet',
  INVITE: 'Invite',
  INVITE_A_LAB_TO_DENTHUB: 'Bjud in ett labb till Denthub',
  SEND_INVITE: 'Skicka inbjudan',
  DONT_SEND_INVITE: 'Skicka ej inbjudan',
  EMAIL_ADDRESS: 'E-post adress',
  ERROR_IN_SENT_INVITATION: 'Fel när inbjudan skickades',
  SUCCESSFULLY_SENT: 'Lyckades att skicka',
  WHEN_ORDER_IS_MODIFIED: 'När order har ändrats',
  WHEN_ORDER_IS_RESEND: 'När order har återskickats',
  IS_MODIFIED: 'har ändrats',
  IS_RESEND: 'har återskickats',
  INVITE_LAB_MODAL_DESCRIPTION:
    'En e-post skickas för att informera labbet att du vill beställa protetik av dem. Kontrollera att e-postadressen är korrekt:',
  ORG_NUMBER: 'Org num.',
  // Organization setting
  CHOOSE_ORGANIZATION_SETTINGS: 'Välj organisationsinställningar',
  REPORTS: 'Rapporter',
  ORDER2: 'Order 2',
  // Manage action
  MANAGE_ACTIONS: 'Åtgärder',
  EDIT_ACTIONS: 'Ändra åtgärder',
  ADD_AN_ACTION: 'Lägg till en åtgärd',
  EDIT_AN_ACTION: 'Ändra en åtgärd',
  ADD_MAIN_CATEGORY: 'Lägg till en huvudkategori',
  MAIN_CATEGORY: 'Huvudkategori',
  MAIN_CAT: 'Huvud kat.',
  SUB_CAT: 'Under kat.',
  SUB_CATEGORY: 'Underkategori',
  ALL: 'Alla',
  RED: 'Röd',
  ADD_SUB_CATEGORY: 'Lägg till underkategori',
  EDIT_MAIN_CATEGORY: 'Ändra huvudkategori',
  EDIT_SUB_CATEGORY: 'Ändra underkategori',
  SELECT_MAIN_CATEGORY: 'Välj huvudkategori',
  SELECT_SUB_CATEGORY: 'Välj underkategori',
  SELECT_MATERIAL_GROUP: 'Välj materialgrupp',
  CHOOSE_HOW_TO_SELECT_TEETH: 'Ange hur tänder ska väljas:',
  SINGLE_TOOTH: 'Enstaka tänder',
  UP_LOW_JAW: 'Över-/underkäke',
  HIDDEN_TEXT: 'Dold text',
  INCLUDE: 'Inkludera',
  MANDATORY: 'Obligatorisk',
  COLOR_SHADE: 'Färg/skala',
  NO_OF_UNITS: 'Antal enheter',
  INTEGER: 'Heltal',
  INT: 'Heltal',
  HEADER: 'Rubrik',
  NO_OF_DEICMALS: 'Antal decimaler',
  ADD_MATERIAL_GROUP: 'Lägg till materialgrupp',
  EDIT_MATERIAL_GROUP: 'Ändra materialgrupp',
  ADDED_MATERIALS: 'Material',
  ADD_ACTION: '+ Lägg till',
  ENTER_MATERIAL_NAME: 'Ange namn på material',
  MATERIAL_GROUP: 'Materialgrupper',
  CATEGORY_CREATED_SUCCESSFULLY: 'Kategori skapades',
  CATEGORY_UPDATED_SUCCESSFULLY: 'Kategori uppdaterades',
  CATEGORY_DELETED_SUCCESSFULLY: 'Kategori togs bort',
  ACTION_CREATED_SUCCESSFULLY: 'Åtgärd skapades',
  ACTION_UPDATED_SUCCESSFULLY: 'Åtgärd uppdaterades',
  ACTION_DELETED_SUCCESSFULLY: 'Åtgärd togs bort',
  ERROR_IN_GETTING_CATEGORIES: 'Fel när kategorier hämtades',
  ERROR_IN_CREATING_CATEGORY: 'Fel när kategori skapades',
  ERROR_IN_UPDATING_CATEGORY: 'Fel när kategori uppdaterades',
  ERROR_IN_DELETING_CATEGORY: 'Fel när kategori togs bort',
  ERROR_IN_GETTING_ACTIONS: 'Fel när åtgärder hämtades',
  ERROR_IN_CREATING_ACTION: 'Fel när åtgärder skapades',
  ERROR_IN_UPDATING_ACTION: 'Fel när åtgärder uppdaterades',
  ERROR_IN_DELETING_ACTION: 'Fel när åtgärder togs bort',
  MATERIAL_CREATED_SUCCESSFULLY: 'Material skapades',
  MATERIAL_UPDATED_SUCCESSFULLY: 'Material uppdaterades',
  MATERIAL_DELETED_SUCCESSFULLY: 'Material togs bort',
  ERROR_IN_CREATING_MATERIAL: 'Fel när material skapades',
  ERROR_IN_UPDATING_MATERIAL: 'Fel när material uppdaterades',
  ERROR_IN_DELETING_MATERIAL: 'Fel när material togs bort',
  ERROR_IN_GETTING_MATERIALS: 'Fel när material hämtades',
  CLASP_TEETH: 'Klammertänder',
  CLASP_MATERIAL: 'Klammermaterial',
  ORTH_COLORS: 'Orto. färger',
  FACIAL_LOOP: 'Facialslinga',
  CLASP_TYPES: 'Klammertyp',
  SPRING_TYPES: 'Fjädertyper',
  SPRING_TEETH: 'Fjädertand',
  EXPANSION_SCREW: 'Expansionskruv',
  PROTRUSION: 'Protrusion',
  ORTHODONTICS_COLORS: 'Ortodonti färger',

  // Manage labs
  FILTER_LABS: 'Filtrera labb:',
  CONNECT_TO_LABS: 'Koppla upp mot labb',
  CONNECT_TO_NEW_LABS: 'Koppla upp mot nytt labb',
  PRICE_LISTS: 'Prislistor',
  AVAILABILITY: 'Tillgänglighet',
  LAB_AVAILABILITY: 'Labbtillgänglighet',
  SELECT_THE_DAYS_THE_LAB_IS_UNAVAILABLE:
    'Ange de dagar som labbet inte är tillgänglig',
  DURING_UNAVAILABLE_DAYS_NO_ORDER_WILL_BE_RECEIVED:
    'Inga beställningar tas emot under dessa dagar.',
  BACK_HANDLING_LABS: 'Tillbaka till hantera labb',
  ORG_NUM: 'Org. nummer',
  ERROR_IN_CONNECT_LABS: 'Fel vid uppkoppling av labb.',
  YEAR: 'År:',
  MONTHS: 'Månader:',
  IN: 'i %',
  ERROR_IN_GETTING_PRICE_LIST: 'Fel när prislistor hämtades',
  ERROR_IN_REMOVING_PRICE_LIST: 'Fel vid borttagning av prislistor',
  ERROR_IN_ADDING_PRICE_LIST: 'Fel när prislistor hämtades',
  PRICE_LIST_ADD_SUCCESS: 'Prislista lades till',
  PRICE_LIST_REMOVED_SUCCESS: 'Prislista togs bort',
  EDIT_LABS: 'Ändra labb',
  ADDRESS: 'Adress',
  TELEPHONE_NUMBER: 'Telefonnumber',
  WEB: 'Webb',
  THIS_LAB_IS_DISABLED: 'Detta labb är inaktiverat',
  CODE: 'Kod',
  ADD_PRICE_LIST: 'Lägg till prislista',
  DISABLE_LAB: 'Inaktivera labb',
  ENABLE_LAB: 'Aktivera labb',
  ENTER_LAB_RANKING: 'Ange rangordning',

  // ORDER DETAILS NEW PAGE
  ORDER_INFO: 'Order',
  INVOICE_NUMBER: 'Fakturanummer',
  INTERNAL_ORDER_ID: 'Internt order-id',
  LAB_AND_DELIVERY: 'Labb och leverans',
  CLINIC_INFO: 'Klinik',
  TEL: 'Tel',
  CONTACT_INFO: 'Kontakt',
  ORDER_CREATED_BY: 'Order skapad av',
  CHANGE_TO: 'ÄNDRA TILL',
  ADDED_MATERIAL_OR_ACTIONS: 'Lägg till material och åtgärder',
  MATERIAL_ACTION: 'Material/åtgärder',
  NEW_FREE_TEXT: 'Ny rad med fritext...',
  QUANTITY: 'Kvantitet',
  SEARCH_OR_CHOOSE_OPTION: 'Sök eller välj alternativ...',
  WRITE_HERE: 'Skriv här...',
  ENTER: 'Ange...',
  APPROVE_THE_ORDER: 'Ange ordern som klar och utlämnat',
  APPROVE_WARNING_MESSAGE: 'Bekräfta ifall priset är korrekt:',
  CONFIRM_PRICE: 'Ja, priset från labbet är korrekt och jag godkänner fakturan!',
  CONFIRM_CERTIFY_PRICE:
    'Ja, priset från labbet är korrekt och jag godkänner och attesterar fakturan!',
  RATE_ORDER_MODAL_MESSAGE: 'Är du nöjd med ordern?',
  SATISFIED: 'Nöjd',
  NOT_SATISFIED: 'Inte nöjd',
  WHAT_ARE_YOUR_REMARKS: 'Ange dina anmärkningar?',
  YOU_MUST_CHOOSE_AT_LEAST_ONE_REMARK: 'Du måste välja minst en anmärkning.',
  ESTETICS: 'Estetik',
  FITTING: 'Passform',
  SERVICE_AND_AVAILABILITY: 'Service och tillgänglighet',
  DEVIATION_FROM_DESCRIPTION: 'Avvikelse från beskrivning',
  RATE_THE_ORDER: 'Betygsätt ordern',
  NO_RATE_THE_ORDER: 'Nej, betygsätt inte ordern',
  YES_RATE_THE_ORDER: 'Ja, betygsätt ordern',
  PRICE_SPECIFICATION: 'Prisspecifikation',
  ACTIONS_AND_MATERIALS: 'Åtgärder och material',
  Total: 'Totalt',
  TOTAL_SUM: 'Total summa',
  APPROVED: 'KLART/UTLÄMNAT',

  /**
   * Federated login
   */
  EXECUTING_FEDERATED_LOGIN: 'Loggar in...',
  SELECT_ORGANIZATION: 'Välj organisation',
  YOU_HAVE_BEEN_LOGGED_OUT: 'Du är nu utloggad!',
  FEDERATED_ERROR: 'Fel vid federerat login!',
  NO_FEDERATED_USER: 'Det finns ingen användare för denna inloggningsmetod!',

  // Customer settings
  CUSTOMER_SETTINGS_FOR: 'Kundinställningar för',
  ID: 'ID',
  SETTING: 'Inställning',
  DEFAULT_VALUE: 'Standardvärde',
  SET_VALUE: 'Satt värde',
  DEFAULT_LANGUAGE: 'Standardspråk',
  DEFAULT_LANGUAGE_DESCRIPTION: 'Standardspråk i Denthub',
  CURRENCY: 'Currency',
  CURRENCY_DESCRIPTION:
    'The used currency in the system, primarily used when action-based orders are used where prices per action is set.',
  CUSTOM_TRANSLATIONS: 'Custom translations',
  CUSTOM_TRANSLATIONS_DESCRIPTION:
    'Contains customer specific translations. The value should contain json formatted data on the same format as “language objects” in the codebase (for example “swedish-translation.js”).',
  EMAIL_EXT_USER_ASSIGNED_NOTIFICATION: 'Email external user assigned notification',
  EMAIL_EXT_USER_ASSIGNED_NOTIFICATION_DESCRIPTION:
    'Controls if an email notification should be sent when an order is shared with an external user.',
  DEFAULT_LOGIN_METHOD: 'Inloggningsmetod',
  DEFAULT_LOGIN_METHOD_DESCRIPTION:
    'Styr vilken loginsida en användare skickas till vid utloggning',
  API_USERNAME: 'API-användarnamn',
  API_USERNAME_DESCRIPTION:
    'Styr vilket användarnamn en integratör skall använda för API-anrop knutet till denna kund',
  API_PASSWORD: 'API-lösenord',
  API_PASSWORD_DESCRIPTION:
    'Styr vilket lösenord en integratör skall använda för API-anrop knutet till denna kund',
  API_PUBLIC_KEY: 'API-Publik nyckel',
  API_PUBLIC_KEY_DESCRIPTION:
    'Styr vilken publik nyckel som ska användas för API-anrop - Måste vara Base64 enkodad',
  ORDER_VERSION: 'Orderversion',
  ORDER_VERSION_DESCRIPTION:
    'Controls which version of new order module to be used.',
  ORDER_DOCUMENT_FORMAT: 'Order Document Format',
  ORDER_DOCUMENT_FORMAT_DESCRIPTION:
    'Controls the height and width of the order PDF document.',
  ORDER_DOCUMENT_MARGINS: 'Order Document Margins',
  ORDER_DOCUMENT_MARGINS_DESCRIPTION:
    'Controls the margins(in mm) on the order pdf document',
  LABEL_DOCUMENT_FORMAT: 'Label Document format',
  LABEL_DOCUMENT_FORMAT_DESCRIPTION:
    'Controls the height(in mm) and width(in mm) of the label(etikett) document',
  LABEL_DOCUMENT_MARGINS: 'Label Document margins',
  LABEL_DOCUMENT_MARGINS_DESCRIPTION:
    'Controls the margins(in mm) on the label(etikett) pdf document',
  ORDER_RECEIPT_FORMAT: 'Order Receipt format',
  ORDER_RECEIPT_FORMAT_DESCRIPTION:
    'Controls the height(in mm) and width(in mm) of the order receipt document',
  PATIENT_CATEGORY_DESCRIPTION:
    'Only for action-based orders. Activates the function patient category. This function is only used in actions based orders.',
  PATIENT_CATEGORIES: 'Patientkategorier',
  PATIENT_CATEGORIES_ADULT: 'Vuxen',
  PATIENT_CATEGORIES_CHILD: 'Barn',
  PATIENT_CATEGORIES_ADULT_PREVENTIVE: 'Vuxen, frisktandvård',
  PATIENT_CATEGORIES_DESCRIPTION:
    'Only for action-based orders. The possible values of the patient categories. The value should contain json formatted data.',
  CHOOSE_LAB_EXCEPTIONS: 'Välj ett anledning till avsteg',
  CHOOSE_LAB_EXCEPTIONS_TECHNICAL: 'Teknisk',
  CHOOSE_LAB_EXCEPTIONS_DISTANCE: 'Avstånd',
  CHOOSE_LAB_EXCEPTIONS_LANGUAGE: 'Språk',
  CHOOSE_LAB_EXCEPTIONS_PATIENT_NEEDS: 'Patients behov',
  CHOOSE_LAB_EXCEPTIONS_DESCRIPTION:
    'Only for action-based orders. The possible options that user can choose as exceptions. The value should contain json formatted data.',
  CLASP_MATERIAL_GOLD: 'Guld',
  CLASP_MATERIAL_STEEL: 'Stål',
  CLASP_MATERIAL_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  ORTHODONTICS_COLORS_RED: 'Röd',
  ORTHODONTICS_COLORS_BLUE: 'Blå',
  ORTHODONTICS_COLORS_GREEN: 'Grön',
  ORTHODONTICS_COLORS_ORANGE: 'Orange',
  ORTHODONTICS_COLORS_YELLOW: 'Gul',
  ORTHODONTICS_COLORS_PURPLE: 'Lila',
  ORTHODONTICS_COLORS_PINK: 'Rosa',
  ORTHODONTICS_COLORS_TRANSPARENT: 'Genomskinlig',
  ORTHODONTICS_COLORS_DESCRIPTION:
    'Only for action-based orders. This is colors related to bitetrays and orthodontics, which differs from the already existing list of shade/colors.',
  FACIAL_LOOP_FACIAL_LOOP: 'Facialslinga',
  FACIAL_LOOP_BASS_SPRING: 'Bassfjäder',
  FACIAL_LOOP_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  CLASP_TYPES_ADAM: 'Adamklammer',
  CLASP_TYPES_BALL: 'Kulklammer',
  CLASP_TYPES_ARROW: 'Pilklammer',
  CLASP_TYPES_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  SPRING_TYPES_Z_SPRING: 'Z-fjäder',
  SPRING_TYPES_EXPANSION_SCREW: 'Expansionskruv',
  SPRING_TYPES_TELESCOPE_SCREW: 'Teleskopskruv',
  SPRING_TYPES_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  METAL_DESIGN_NO_METAL_MARGIN: 'Ingen metallmarginal',
  METAL_DESIGN_PORCELAIN_MARGIN: 'Skulderporslin',
  METAL_DESIGN_SMALL_METAL_MARGIN: 'Liten metallmarginal',
  METAL_DESIGN_LINGUAL_METAL_MARGIN: 'Lingual metall marginal',
  METAL_DESIGN_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  PONTIC_DESIGN_SANITARY: 'Sanitary',
  PONTIC_DESIGN_BULLET: 'Äggformad',
  PONTIC_DESIGN_MOD_RIDGE_LAP: 'Sadelformad',
  PONTIC_DESIGN_FULL_RIDGE_LAP: 'Full Ridge Lap',
  PONTIC_DESIGN_OVATE: 'Ovate',
  PONTIC_DESIGN_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  OCCLUSION_TYPE: 'Occlusion type',
  OCCLUSION_TYPE_HARD: 'Hård',
  OCCLUSION_TYPE_NORMAL: 'Normal',
  OCCLUSION_TYPE_LIGHT: 'Lätt',
  OCCLUSION_TYPE_NONE: 'Ingen',
  OCCLUSION_TYPE_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  CONTACT_TYPE_HARD: 'Hård',
  CONTACT_TYPE_NORMAL: 'Normal',
  CONTACT_TYPE_LIGHT: 'Lätt',
  CONTACT_TYPE_NONE: 'Ingen',
  CONTACT_TYPE_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  TESTING_STEPS_TESTING: 'Provning',
  TESTING_STEPS_COMPLETE: 'Färdig konstruktion',
  INVOICING_MODULE: 'Invoicing module',
  INVOICING_MODULE_DESCRIPTION: 'Om faktureringsmodulen är aktiverad eller ej.',
  INVOICING_PERIOD: 'Faktureringsperiod',
  INVOICING_PERIOD_DESCRIPTION:
    'Tiden(i dagar) när en faktura som är redo ska skickas.',
  INVOICING_CONTACT_NAME: 'Kontaktperson',
  INVOICING_CONTACT_NAME_DESCRIPTION: 'Namn på kontaktperson',
  INVOICING_CONTACT_EMAIL: 'Email',
  INVOICING_CONTACT_EMAIL_DESCRIPTION: 'Kontaktpersonens email',
  ORDER_PREFILL: 'Order prefill',
  ORDER_PREFILL_DESCRIPTION:
    'Enabled if the orders may be initiated by the order prefill endpoint.',
  ORDER_PREFILL_CLINIC_EXT_ID: 'Order prefill clinic ext_id',
  ORDER_PREFILL_CLINIC_EXT_ID_DESCRIPTION:
    'Decides which clinic external ID to used when matching to the clinic organizations.',
  RATING_REMARKS: 'Betygsättning anmärkningar ',
  RATING_REMARKS_ESTHETICS: 'Estetik',
  RATING_REMARKS_FITTING: 'Passform',
  RATING_REMARKS_SERVICE_AVAILABILITY: 'Service och tillgänglighet',
  RATING_REMARKS_SERVICE_AND_AVAILABILITY: 'Service och tillgänglighet',
  RATING_REMARKS_OCCLUSION: 'Ocklusion',
  RATING_REMARKS_OTHER: 'Övrigt',
  RATING_REMARKS_ESTETICS: 'Estetik',
  RATING_REMARKS_DEVIATIONS_FROM_ORDER: 'Avvikelser från anvisning',
  RATING_REMARKS_DEVIATION_FROM_DESCRIPTION: 'Avvikelser från beskrivning',
  RATING_REMARKS_DELIVERY_TIME: 'Leveranstid',
  RATING_REMARKS_DESCRIPTION:
    'Only for action-based orders. The list of available remarks to choose from when the predefined lab is not chosen. The value should contain json formatted data.',
  APPROVED_STATUS: 'Klart/utlämnat status',
  APPROVED_STATUS_DESCRIPTION: 'Enables if the status DONE/HANDED OUT for dentists.',
  BACK_TO_MANAGE_CLINICS: 'Tillbaka till Kliniker',
  BACK_TO_MANAGE_LABS: 'Tillbaka till Hantering av labb',
  ERROR_IN_GETTING_ORGANIZATION: 'Fel när organisationer hämtades',
  ERROR_IN_UPDATING_ORGANIZATION: 'Fel när organisationen uppdaterades',
  ERROR_IN_GETTING_SETTINGS: 'Fel när inställningar hämtades',
  SETTINGS_SAVED_SUCCESSFULLY: 'Inställningar sparades',
  IMPLANT_STEPS_INDIVIDUAL_TRAY: 'Individuell sked',
  IMPLANT_STEPS_BITE_BLOCK: 'Bitschablon',
  IMPLANT_STEPS_DENTITION: 'Tanduppsättning',
  IMPLANT_STEPS_DENTITION_WITH_SKELETON: 'Tanduppsättning med skelett',
  IMPLANT_STEPS_SKELETON_TESTING: 'Provning skelett',
  IMPLANT_STEPS_COMPLETE: 'Färdig konstruktion',
  DENTURE_STEPS_IMMEDIATE: 'Immediatprotes',
  DENTURE_STEPS_INDIVIDUAL_TRAY: 'Individuell sked',
  DENTURE_STEPS_BITE_BLOCK: 'Bitschablon',
  DENTURE_STEPS_DENTITION: 'Tanduppsättning',
  DENTURE_STEPS_COMPLETE: 'Färdig konstruktion',
  PARTIAL_DENTURE_STEPS: 'Partial denture steps',
  PARTIAL_DENTURE_STEPS_INDIVIDUAL_TRAY: 'Individuell sked',
  PARTIAL_DENTURE_STEPS_BITE_BLOCK: 'Bitschablon',
  PARTIAL_DENTURE_STEPS_DENTITION: 'Tanduppsättning',
  PARTIAL_DENTURE_STEPS_COMPLETE: 'Färdig konstruktion',
  EXPORT_PRODUCTION_DESCRIPTION:
    'Only applicable for dentist customers. If the setting is enable the  dentists will be able to select export production or not, depending in if the specfic lab provides that service.',
  RATING_COLUMN: 'Rating column',
  RATING_COLUMN_DESCRIPTION:
    'Only for action-based orders. This enables/disables the rating column on the step where labs are selected.',
  RECENT_LAB_LIST: 'Recent used lab',
  RECENT_LAB_LIST_DESCRIPTION:
    'Activates the functionality where dentists can see the recently used labs based on previously created orders.',
  EXTERNAL_LAB_USERS: 'Externa labbanvändare',
  EXTERNAL_LAB_USERS_DESCRIPTION: 'Aktivera/inaktivera externa labbanvändare.',
  LIMIT_INFO_EXTERNAL_USERS: 'Limit Info for External lab users',
  LIMIT_INFO_EXTERNAL_USERS_DESCRIPTION: 'Limit Info for External lab users.',
  LAB_WORKFLOW: 'Arbetsflöde för labb',
  LAB_WORKFLOW_DESCRIPTION: 'Aktivera/inaktivera arbetsflöde för labb.',
  PRIVATE_ELASTIC_DASHBOARD: 'Privat Elastic space',
  PRIVATE_ELASTIC_DASHBOARD_DESCRIPTION:
    'Namnet på ett privat Elastic space. Funktionen är endast aktiv om ett namn är givet.',
  // Manage permissions module
  MANAGE_PERMISSIONS: 'Behörigheter',
  PERMISSIONS: 'Behörigheter',
  ADD_ROLE: 'Lägg till roll',
  EDIT_ROLE: 'Ändra roll',
  ROLE_NAME: 'Namn på roll...',
  ERROR_IN_GETTING_ROLES: 'Fel när roller hämtades',
  ERROR_IN_GETTING_PERMISSIONS: 'Fel när behörigheter hämtades',
  ERROR_IN_GETTING_ROLE_PERMISSIONS: 'Fel när rollbehörigheter hämtades',
  ERROR_IN_ADDING_ROLE: 'Fel när roller sparades',
  ERROR_IN_UPDATING_ROLE: 'Fel när roll uppdaterades',

  TECHNICIAN_ADMIN: 'Tandtekniker (Admin)',
  TECHNICIAN_USER: 'Tandtekniker',
  DENTIST_ADMIN: 'Behandlare (Admin)',
  DENTIST_USER: 'Behandlare',
  DENTIST_CLINIC_MANAGER: 'Klinik-admin',
  LIMITED_DENTIST: 'Behandlare',

  // Order filters
  MY_ORDERS: 'Mina ordrar',
  APPROVED_BY_DENTIST: 'Klart/Utlämnat',
  APPROVED_BY_DENTIST_BUTTON: 'Klart/Utlämnat',
  SELECT_CLINIC: 'Välj klinik',

  // lab-technician page
  SELECT_ROLE: 'Välj roll',
  SELECT_LAB: 'Välj labb',

  // manage clinic page
  SELECT_PARENT_CLINIC: 'Välj huvudklinik',
  SELECT_DEFAULT_CLINIC: 'Välj standardklinik',
  INVALID_EMAIL: 'Ange en korrekt e-postadress',

  NO_LABS_WITH_MATCHING_PRICE_LISTS_FOUND:
    'Hittade inga labb med matchande prislista!',
  PRINT_IT: 'Skriv ut',
  SELECT_ACTIONS: 'Välj åtgärder...',
  USER_IS_INACTIVE: 'Användare är inaktiverad',
  ERROR_IN_SENDING_EMAIL: 'Fel när e-post skickades',
  NO_TEETH_SELECTED: 'Inga tänder valda',

  // Some placeholder translations
  CATEGORY_NAME: 'Namn på kategori...',
  ENTER_INSTRUCTIONS: 'Ange instruktioner...',
  'NO.': 'Num...',
  Name: 'Namn...',
  SEARCH_FOR_A_ACTION_EITHER_BY_NAME_OR_NUMBER: 'Sök efter en åtgärd...',
  FILTER_ON_NAME_OR_ORG_NUMBER: 'Filtrera på namn eller org-nummer',
  WARRANTY: 'Garantiärende (kostnadsfritt)',

  TESTING_STEPS: 'Provningssteg',
  UPDATE_ORDER: 'UPPDATERA ORDER',
  '1_CONSTRUCTIONS': '1 KONSTRUKTIONER',
  '2_LAB_AND_DELIVERY': '2 LABB OCH LEVERANS',
  '3_SIGN_AND_SEND': '3 SIGNERA OCH SKICKA',
  DELIVERY_DETAILS: 'Leveransinformation',

  // Translation for old saved data of simple order construction
  // Please Don't change these translation in swedish
  'METAL_DESIGN_No Metal Margin': 'Ingen metallmarginal',
  'METAL_DESIGN_Porcelain Margin': 'Porslinsmarginal',
  'METAL_DESIGN_Small Metal Margin': 'Liten metallmarginal',
  'METAL_DESIGN_Lingual Metal Margin': 'Lingual metallmarginal',
  PONTIC_DESIGN_Sanitary: 'Sanitary',
  PONTIC_DESIGN_Bullet: 'Äggformad',
  'PONTIC_DESIGN_Mod. Ridge Lap': 'Sadelformad',
  'PONTIC_DESIGN_Full Ridge Lap': 'Full Ridge Lap',
  PONTIC_DESIGN_Ovate: 'Ovate',
  OCCLUSION_TYPE_Hard: 'Hård',
  OCCLUSION_TYPE_Light: 'Lätt',
  OCCLUSION_TYPE_None: 'Ingen',
  CONTACT_TYPE_Hard: 'Hård',
  CONTACT_TYPE_Light: 'Lätt',
  'IMPLANT_STEPS_Individual tray': 'Individuell sked',
  'IMPLANT_STEPS_Bite Block': 'Bitschablon',
  IMPLANT_STEPS_Dentition: 'Tanduppsättning',
  'IMPLANT_STEPS_Dentition with skeleton': 'Tanduppsättning med skelett',
  'IMPLANT_STEPS_Skeleton testing': 'Provning skelett',
  'IMPLANT_STEPS_Complete construction': 'Färdig konstruktion',

  // SITHS Login
  SIGN_IN_WITH_E_SERVICE_CARD_SITHS: 'Logga in med e-tjänstekort(SITHS)...',

  // BankID Login
  SIGN_IN_WITH_BANKID: 'Logga in med BankId...',

  SELECT_DATE: 'Välj datum...',
  ORDER_VERSION_SETTINGS_DOES_NOT_MATCH:
    'Inställningar för orderversion matchar inte.',
  ORDER_UPDATED: 'Ordern uppdaterad',
  ORDER_PRICE_NEGATIVE: 'Totalt pris får inte vara negativt',
  ACTION_COST_PER_TOOTH: 'Åtgärdskostner per tand',
  COST_PER_TOOTH: 'Kost. per tand',
  ORDER_RESEND: 'Återskicka order',
  SAVE_ORDER_INFO: 'Order-info sparad',
  SAVE_LAB_HANDLE_REQUEST: 'Hantering av hämtningen sparad',
  SUCCESSFULLY_UPDATED_LAB_FLAG: 'Uppdatering av flagga sparad',

  // Reports
  REPORT: 'Rapport',
  CHOOSE_REPORT: 'Välj Rapport',
  FOR_WHOM: 'För vem',
  CHOOSE_DENTIST: 'Välj behandlare',
  CREATE: 'Visa',
  CREATE_REPORT_HELP_TEXT: 'För att visa rapport, fyll i fälten.',
  NO_REPORT_DATA: 'Ingen rapportdata finns tillgänglig för de valda datumen.',
  NUMBER_OF_ORDERS: 'Antal beställningar',
  TOTAL_AMOUNT: 'Totalbelopp',
  ECONOMY: 'Ekonomi',
  LAB_RATING: 'Betygsättning av laboratorium',
  RANKING_ADHERENCE: 'Följsamhet till rangordning',
  RANKING_VIOLATIONS: 'Avsteg från rangordning',
  DOWNLOAD_REPORT: 'Ladda ner rapport',
  SATISFIED_PERCENTAGE: 'Nöjd %',
  NOTSATISFIED_PERCENTAGE: 'Inte nöjd %',
  ORDER_RATINGS_FOR_LABS: 'Betygsättning per beställning',
  ORDER_RATINGS_FOR_CLINICS: 'Betygsättning, per order',
  ORDERID: 'Order-id',
  RATING_PER_CLINIC: 'Betygsättning per klinik',
  SATISFACTION_PERCENTAGE: 'Nöjdhet %',
  // TODO: Report TEMP VALUES, refactor this
  ADULT: 'Vuxen',
  CHILD: 'Barn',
  ADULT_PREVENTIVE: 'Vuxen, frisktandvård',
  TECHNICAL: 'Teknisk',
  PATIENT_NEEDS: 'Patients behov',
  ADHERENCE: 'Följsamhet',
  DEVIATIONS_FROM_ORDER: 'Avvikelser från anvisning',
  ESTHETICS: 'Estetik',
  SERVICE_AVAILABILITY: 'Service och tillgänglighet',
  NUMBER_OF_TOTAL_ORDERS: 'Totalt antal ordrar',
  NUMBER_OF_RATED_ORDERS: 'Antal betygsatta ordrar',
  SELECT_STATUS: 'Välj status',
  SYNCID: 'SyncID',
  CLINIC_CC: 'Klinik CC',
  ADHERENCE_PERCENTAGE: 'Följsamhet %',

  // Additional dentist
  PATIENT_AND_DENTIST: 'Patient och behandlare',
  YOU: 'du',
  ADD_DENTIST: 'Lägg till behandlare',
  CHANGE_DENTIST: 'ÄNDRA BEHANDLARE',
  SELECT_DENTIST: 'VÄLJ BEHANDLARE',
  SELECT_DENTIST_TEXT: 'Du behöver välja behandlare',
  ADD_DENTIST_TO_ORDER: 'LÄGG TILL BEHANDLARE I ORDERN',
  FILTER: 'Filtrera',
  REMOVE_DENTIST: 'ta bort',
  DENTIST_INFORMATION: 'Info om behandlare',
  ADDITIONAL_DENTIST: 'Behandlare',

  // Rating
  RATED_BY: 'Betygsatt av',
  REMARKS: 'Anmärkningar',

  // Select both jaw
  UPPER_LOWER_JAW: 'Över och underkäke',

  // Exception/reason
  NOT_REQUIRE_EXCEPTION_WHEN_SELECTING_LAB: 'Kräv inga avstegsskäl vid val av labb',

  // Pick up at clinics
  PICK_UP_AT_CLINICS: 'Hämtning på kliniker',
  SELECT_THE_CLINICS_WHERE_YOUR_LAB:
    'Välj kliniker där de erbjuder hämtning av avtrycket',
  CONNECTED_CLINICS: 'Anslutna kliniker...',
  PICK_UP_AT_CLINIC: 'Hämtning på klinik',
  REQUEST_PICK_UP_OF_THE_SAMPLE_AT_THE_CLINIC:
    'Begär hämtning av avtrycket på kliniken',
  PICK_UP_REQUESTED: 'Hämtning beställd?',
  HAS_THE_LAB_HANDLED_THE_REQUEST: 'Har labbet hanterat begäran?',
  INVALID_TIME: 'Inkorrekt tid',

  // Invoicing
  INVOICING: 'Fakturering',
  ENABLE_INVOICING_OF_CLINICS: 'Enable invoicing of clinics',
  INVOICE_INFO: 'Fakturainfo',
  CONFIGURATION: 'Konfiguration',
  CLINIC_SETTINGS: 'Klinikinställningar',
  VAT_NO: 'Moms-nummer',
  REFERENCE: 'Referens',
  PAYMENT_DETAILS: 'Betalningsdetaljer',
  POSTGIRO: 'Postgiro',
  BANKGIRO: 'Bankgiro',
  BIC_SWIFT: 'BIC/Swift',
  IBAN_ACCOUNT_NUMBER: 'IBAN kontonummer',
  CITY_NAME: 'Stad',
  STREET_NAME: 'Gatunamn',
  INVOICE_NUMBER_GENERATION: 'Generera fakturanummer automatiskt',
  MANUALLY_SET_PER_ORDER: 'Sätts manuellt per order',
  NUMBER_STARTING_FROM: 'Nummer startar från',
  STARTING_NUMBER: 'Startnummer',
  MONTHLY_INVOICE_CONFIGURATION: 'Inställningar för samlingsfaktura',
  THESE_SETTING_ARE_USED_WHEN_MONTHLY_INVOICE_IS_SELECTED_FOR_THE_CUSTOMER_CLINIC:
    'Dessa inställningar används när samligsfaktura är valt för kunden/kliniken.',
  CREATE_THE_MONTHLY_INVOICE_ON_THIS_DAY_OF_THE_MONTH:
    'Skapa den månatliga fakturan denna dag vaje månad',
  SELECT_INVOICE_METHOD: 'Välj faktureringsmetod',
  ENABLE: 'Aktivera',
  PAYMENT_TERM: 'Betalvillkor',
  INVOICE_METHOD: 'Faktureringsmetod',
  PER_ORDER: 'Per order',
  MONTHLY: 'Månatligen',
  INVOICE_DATE: 'Fakturadatum',
  E_INVOICE_STATUS: 'E-faktura status',
  READY_FOR_INVOICE: 'Klar för fakturering',
  SENT: 'Skickat',
  COMPLETE: 'Färdig',
  INVOICE_CREATED: 'Faktura skapad',
  AMOUNT: 'Belopp',
  VAT: 'Moms',
  '1_MONTH': '1 månad',
  '3_MONTH': '3 månader',
  ERROR_IN_GETTING_INVOICES: 'Fel vid hämtning av fakturor',
  INVALID_ZIP_CODE: 'Fel postkod',
  ERROR_IN_CREATING_INVOICE: 'Ett fel inträffade. Fakturan kunde inte skapas.',
  INVOICE_SENT_DATE: 'Faktura skickad',
  INVOICE_CREATED_SUCCESSFULLY: 'Order är klar för fakturering',
  INVOICE_UPDATED_SUCCESSFULLY: 'Fakturan har uppdaterats',
  ERROR_IN_UPDATING_INVOICE: 'Ett fel inträffade. Fakturan kunde inte uppdateras.',

  // Clinics new field
  ORG_NO: 'Org. nummer',
  THIS_CLINIC_IS_NOT_A_PICK_UP_AND_DELIVERY_PLACE:
    'Denna klinik är inte en hämtning eller leveransplats',
  NO_USER_AVAILABLE: 'Inga användare tillgängliga',

  // Add action new field
  ENTER_THE_VAT: 'Ange moms (i %)',
  GLN: 'GLN',

  // Price-difference
  MATERIAL_GROUPS: 'Prisskillnader för materialgrupper',
  MATERIAL_GROUP_NAME: 'Namn på materialgrupp',
  MATERIAL_NAME: 'Materialnamn',
  PRICE_DIFFERENCE: 'Prisskillnad',
  PRICE_DIFFERENCE_UPDATED_SUCCESSFULLY: 'Prisskillnad uppdaterades',
  ERROR_IN_UPDATING_PRICE_DIFFERENCE: 'Fel vid uppdatering av prisskillnad',

  ADDITION: 'Tillägg',
  DEDUCTION: 'Avdrag',
  TOTAL: 'Totalt',

  // Sample method customer setting
  SAMPLE_METHOD: 'Metod för avtryckstagning',
  SAMPLE_METHOD_DESCRIPTION: 'Den metod som användes vid avtryckstagning.',
  SAMPLE_METHOD_LABEL: 'Hur togs avtrycket?',
  SAMPLE_TAKEN: 'Avtrycksmetod',
  PHYSICAL: 'Fysisk',
  DIGITAL: 'Digital',

  // Sample package content
  SAMPLE_CONTENT: 'Avtryck och innehåll',
  SAMPLE_CONTENT_DESCRIPTION:
    'Sample content button used by the dentist when the sample was taken.',
  SAMPLE_CONTENT_SAMPLE: 'AVTRYCK',
  SAMPLE_CONTENT_STUDY_MODEL: 'STUDIEMODELL',
  SAMPLE_CONTENT_INDEX: 'INDEX',
  SAMPLE_CONTENT_PLASTER_MODEL: 'GIPSMODELL',
  SAMPLE_CONTENT_IMPLANT_COMPONENTS: 'IMPLANTATKOMPONENTER',
  SAMPLE_CONTENT_DOWEL_PINS: 'STYRPINNAR',
  SAMPLE_CONTENT_COLOR_SAMPLE: 'FÄRGPROV',
  COMMENTS_ABOUT_THE_SAMPLE: 'Kommentarer avseende innehållet i lådan',
  SAMPLE_AND_PACKAGE_CONTENT: 'Avtryck och innehåll i lådan',
  CONTENT_IN_DELIVERY: 'Innehållet i lådan',
  CONTENT_COMMENTS: 'Kommentarer',
  SAMPLE_INFO: 'Innan du byter status, ange innehållet i lådan',
  INTERNAL_NUMBER: 'Internal number',
  NOT_GENERATED: 'Ej skapad',
  PROSTHODONTICS: 'Tandteknik',
  ERROR_IN_CREATING_PDF: 'Fel vid skapande av PDF',
  CURRENT_COMMENT: 'Befintlig kommentar',

  // Role modal
  ROLE_TYPE: 'Rolltyp',
  BOTH: 'Båda',

  // Organization number
  INVALID_ORG_NO: 'Fel Org-nummer',

  // Existing patient order
  EXISTING_ORDER_INFO:
    'OBS!! Det finns redan aktiva ordrar för denna patient. Om denna beställning gäller ett steg i en redan redan pågående order bör du\n' +
    'istället välja denna order från listan nedan. På detaljsidan för ordern väljer du sedan “Återskicka” för att lägga din beställning. Om denna \n' +
    'beställning inte är relaterad till en pågående order kan du bocka för rutan nedan och fortsätta.',
  THE_NEW_ORDER_IS_NOT_RELATED_TO_EXISTING_ORDER:
    'Denna beställning är ny och inte relaterad till någon pågående order.',

  // File size error
  FILE_IS_TOO_LARGE_TO_UPLOAD:
    'Filen är för stor för uppladdning. Maximal tillåtna storlek är 800 MB.',

  // Automatic process for archive/approve/rating
  AUTO_APPROVE: 'Automatisk godkännande',
  AUTO_APPROVE_DESCRIPTION:
    'Antalet dagar efter, som den automatiska processen för arkivering/godkännande/betygsättning körs',

  NO_PERSONAL_IDENTITY_NUMBER: 'Följer ej formatet för svenskt personnummer',
  NOTIFICATION_CLEARED: 'Alla notifieringar har markerats som lästa',
  ERROR_IN_CLEAR_NOTIFICATIONS: 'Fel vid rensning av alla notifieringar',
  INVALID_VAT_NO: 'Fel momsnummer',

  // Completed order in report
  ONLY_COMPLETED_IF_ORDERS_HAS_MULTIPLE_STEPS:
    'Hämta enbart beställningar som är färdiga ifall de kräver flera steg',

  // Invoice pdf
  THE_PDF_INVOICE_COULD_NOT_BE_DOWNLOADED: 'PDF fakturan kunde inte laddas ned!',
  INVOICE_DOWNLOADED_SUCCESSFULLY: 'Fakturan laddas ner',

  // locale format
  FORMATTING_OF_DATES_AND_NUMBERS: 'Formatting of dates and numbers',
  LOCALES: 'Locales',
  REGION: 'Region',

  // bank Id login
  SIGN_IN_WITH_SWEDISH_BANKID: 'Logga in med Svenskt BankID',
  PERSONAL_IDENTIFICATION_NUMBER: 'Personnummer',
  ENABLE_BANKID: 'Aktivera BankID',
  DISABLE_BANKID: 'Inaktivera BankID',
  CANCEL_BANKID: 'Avbryt BankID',
  BANK_ID_ENABLED: 'BankID aktiverat',
  BANK_ID_NOT_ENABLED: 'BankID ej aktiverat',
  BANKID_ON_OTHER_DEVICE: 'BankID på annan enhet',
  BANKID_ON_SAME_DEVICE: 'BankID på denna enhet',
  SIGN_IN_WITH_BANK_ID: 'Logga in med BankID',
  SUCCESSFULLY_ACTIVATE_BANKID: 'Aktiverade BankID!',
  SUCCESSFULLY_DEACTIVATE_BANKID: 'Inaktiverade BankID!',
  ERROR_ACTIVATE_BANKID: 'Fel vid aktivering av BankID',
  ERROR_DEACTIVATE_BANKID: 'Fel vid inaktivering av BankID',
  ERROR_FEDERATED_OPERATION: 'Fel vid federerat funktionsanrop!',
  SELECT_BANKID_METHOD: 'Välj BankID-metod:',

  // report description
  REPORT_DESCRIPTION: 'Info om rapporten',
  ERROR_IN_GETTING_REPORT_DESCRIPTION: 'Fel när rapportinfo hämtades',
  ERROR_IN_GETTING_REPORT: 'Fel när rapport hämtades',

  // invoice number mandatory
  INVOICE_NUMBER_MANDATORY:
    'Fakturanumret är obligatoriskt för att ordern ska kunna sättas som klar för fakturering',
  INVOICE_NUMBER_CONFIGURATION_NOT_SET:
    'Set Invoice number configuration before setting ready for invoice',
  ERROR_IN_GETTING_PATIENT_INFO: 'Fel när patientinfo hämtades!',
  NUMBER_OF_COMPLETED_ORDERS_PER_CLINIC: 'Antal färdiga beställningar, per klinik',
  ALL_CLINICS: 'Alla kliniker',
  ECONOMY_DONE_ORDERS_PER_CLINIC:
    'Ekonomirapport över färdiga beställningar, per klinik',
  ECONOMY_DONE_ORDERS_PER_DENTIST:
    'Ekonomirapport över färdiga beställningar, per behandlare',
  NUMBER_OF_COMPLETED_ORDERS: 'Antal färdiga beställningar, per behandlare',
  DUPLICATE_INVOICE_NUMBER:
    'Fakturanumret existerar redan. Skriv in annat fakturanummer.',
  INVOICE_PRICE_MAY_NOT_BE_0: 'Fakturabeloppet får ej vara noll',

  // invoice admin
  INVOICES: 'Invoices',
  INVOICE_INFORMATION: 'Invoice information',
  ERROR_IN_UPDATE_INVOICE_STATUS: 'Error in update invoice status',
  ALL_STATUS: 'All status',
  ALL_LABS: 'Alla Labb',

  //other sample content
  OTHER_CONTENT: 'Annat innehåll',

  // client directory
  CLIENT_DIRECTORY: 'Kundlista',
  DENTISTS: 'Behandlare',
  ADD_DENTISTS: 'Lägg till behandlare',
  UPDATE_DENTISTS: 'Updatera behandlare',
  PHONE_NUMBER: 'Telefonnummer',
  ERROR_IN_CREATING_DENTIST: 'Fel när behandlare skulle läggas till',
  ERROR_IN_GETTING_DENTISTS: 'Fel vid hämtning av behandlare',
  ADD_CLIENT: 'Lägg till kund',
  CLIENT_DETAILS: 'Kundinformation',
  VIEW_AND_EDIT: 'Ändra',
  NEW_CLIENT: 'Ny kund',
  UPDATE_CLIENT: 'Uppdatera kund',
  SELECT_CLIENT: 'Välj kund',
  LOCAL_CLINIC_NOT_FOUND: 'Kunde ej hitta kliniken',
  ERROR_IN_UPDATE_CLIENT: 'Fel vid uppdatering av kund',
  DUPLICATE_LOCAL_CLINIC: 'Dublett av kund ej tillåtet',
  ERROR_IN_ADD_CLIENT: 'Fel vid tillägg av kund',
  CHECK_ALL: 'Välj alla',
  UNCHECK_ALL: 'Uncheck All',
  USE_COMMA_TO_SEPERATE_MULTIPLE_EMAIL_ADDRESSES:
    'Använd komma-tecken för att ange flera adresser',
  ENTER_EMAIL_ADDRESSES: 'Ange e-postadresser',
  CLIENT_INFORMATION: 'Client information',

  // material lists
  MATERIAL_LISTS: 'Materiallistor',
  SELECT_MATERIAL_LIST: 'Välj materiallista',
  CREATE_NEW_LIST: 'Ny materiallista',
  NEW_MATERIAL_LIST: 'Ny materiallista',
  EDIT_MATERIAL_LIST: 'Ändra materiallista',
  MATERIAL_LIST_NAME: 'Namn på materiallista',
  ADD_MATERIAL_LIST: 'Ny materiallista',
  UPDATE_MATERIAL_LIST: 'Updatera materiallista',
  DEFAULT_MATERIAL_LIST: 'Standard materiallista',
  DEFAULT_MATERIAL_LIST_SAVE_SUCCESSFULLY: 'Standard materiallista sparad',
  ERROR_IN_DEFAULT_MATERIAL_LIST: 'Fel i Standard materiallista',
  MATERIALS: 'Material',
  ADD_MATERIAL: 'Lägg till material',
  UPDATE_MATERIAL: 'Uppdatera material',
  SELECT_WHAT_TO_SHOW: 'Välj vad som ska visas',
  MATERIAL_CODE: 'Materialkod',
  BATCH_NUMBER: 'Batchnumber',
  DATE_ADDED: 'Tillagd datum',
  DATE_INACTIVE: 'Inaktiv datum',
  BASE_PRICE: 'Baspris',
  NEW_MATERIAL: 'Nytt material',
  EDIT_MATERIAL: 'Ändra material',
  INACTIVE_MATERIAL: 'Inaktivt material',
  AUTO_MATERIAL_REDUCTION: 'Auto reducering av material',
  ALL_MATERIALS: 'Allt material',
  ERROR_IN_CREATING_MATERIAL_LIST:
    'Ett fel inträffade. Materiallistan kunde inte skapas.',
  MATERIAL_LIST_CREATED: 'Materiallista skapad',
  ERROR_IN_UPDATING_MATERIAL_LIST:
    'Ett fel inträffade. Materiallistan kunde inte uppdateras.',
  MATERIAL_LIST_UPDATED: 'Materiallistan har uppdaterats',
  ASSOCIATED_MATERIAL_LIST: 'Associerad materiallista',
  SELECT_LIST: 'Välj lista',
  NO_MATERIAL_LIST_SELECTED: 'Ingen materiallista vald',
  MATERIAL_UPDATED: 'Material uppdaterat',
  MATERIAL_CREATED: 'Material skapat',
  GENERAL_DISCOUNT: 'Generell rabatt (%)',
  DISCOUNTED_PRICE: 'Rabatterat pris',
  SUCCESSFULLY_SAVED_ASSOCIATED_MATERIAL_LIST:
    'Associerad materiallista har sparats',
  ERROR_IN_SAVED_ASSOCIATED_MATERIAL_LIST:
    'Fel när associated material list sparades',
  TOTAL_NO_VAT: 'Totalt utan moms',
  TOTAL_WITH_VAT: 'Totalt med moms',
  UNIT_PRICE: 'Enhetspris',
  FREE_TEXT: 'Fritext',
  ERROR_IN_UPDATING_ORDER: 'Fel när order skulle uppdateras',
  ADDED_MATERIAL: 'Material',
  ADDED_ACTIONS: 'Åtgärder',
  SHOW_NOTES: 'Visa instruktioner för denna åtgärd',
  ENTER_NOTES: 'Skriv instruktioner för denna åtgärd',
  SUCCESSFULLY_UPDATED_ASSOCIATED_MATERIAL_LIST:
    'Successfully updated associated material list',
  ERROR_IN_UPDATING_ASSOCIATED_MATERIAL_LIST:
    'Error in updating associated material list',

  //order list page invoice status
  DUPLICATE_EMAIL: 'E-postadressen används redan av en annan användare',
  READY_FOR_ORDER_INVOICE: 'Klar för fakturering',
  INVOICE_SENT: 'Klar för fakturering',
  INVOICING_COMPLETED: 'Klar för fakturering',

  //delivery instruction
  DELIVERY_INSTRUCTION: 'Leveransinstruktioner',

  // LOGISTICS
  LOGISTICS: 'Transport',
  LOGISTICS_APP: 'Transport',
  TRANSPORT_DAY: 'Upphämtningsdag',
  LATEST_DELIVERY_DATE: 'Senaste leveransdag',
  ONLY_SHOW_PLANNED: 'Enbart visa planerade',
  FILTER_ON_CLINIC: 'Filtrera på klinik, adress...',
  PICK_UP_OF_SAMPLES_FROM_CLINICS_AND_DELIVERY_TO_LAB: 'HÄMTNINGAR',
  DELIVERY_OF_PACKAGE_FROM_LAB_TO_CLINIC: 'LÄMNINGAR',
  PACKAGES: 'Paket',
  DEADLINE: 'Deadline',
  OK: 'OK',
  PLANNED: 'Beställd',
  PICKED_UP: 'Lastad',
  MISSED: 'Bomkörning',
  REVOKED: 'Makulerad',
  MISSED_PICKUP: 'Ej hämtad',
  MISSED_DELIVERY: 'Ej levererad',
  SCHEDULE_A_NEW_PICK_UP: 'Planera en ny upphämtning',
  UPDATE_A_PICK_UP: 'Uppdatera en upphämtning',
  PLACE_OF_DELIVERY: 'Leveransplats',
  NO_OF_PACKAGES: 'Antal paket',
  REQUESTED: 'Begärd',
  ACTUAL: 'Faktiskt',
  PICK_UP_SCHEDULED: 'Upphämtning planerad',
  PICK_UP_UPDATED: 'Upphämtning uppdaterad',
  PICK_UP_DELETED: 'Upphämtning borttagen',
  AFTER_8: 'Efter 8:00',
  BEFORE_8: 'Före 8:00',
  'No Deadline': 'Ingen deadline',
  UPDATE_DELIVERY: 'Uppdatera leverans',
  SCHEDULE_A_NEW_DELIVERY: 'Planera en ny leverans',
  DELIVERY_SCHEDULED: 'Leverans planerad',
  DELIVERY_UPDATED: 'Leverans uppdaterad',
  DELIVERY_DELETED: 'Leverans borttagen',
  TRANSPORT_SERVICES: 'Transporttjänster',
  DENTHUB_TRANSPORT_SERVICE: 'Denthub Transporttjänster',
  TRANSPORT_INFORMATION:
    'I vissa områden tillhandahåller Denthub tjänster för upphämtning och leveranser av paket. Här kan du aktivera ifall du önskar att använda Denthubs transporttjänster.',
  DENTHUB_TRANSPORT_UPDATED: 'Denthub transport uppdaterad',
  PICK_UP_DATE: 'Upphämtningsdatum',
  DENTHUB_NOT_PICKUP: 'Denthub skall EJ hämta denna paket',
  OK_BUTTON_INSTRUCTION:
    'När du klickar OK kommer du ta bort hämtningen av detta paket!',
  UPDATE_NEW_PICK_UP: 'Uppdatera ny upphämtning',
  MESSAGE_TO_DRIVER: 'Meddelande till förare',

  // date type
  TODAY: 'Idag',
  TOMORROW: 'Imorgon',
  YESTERDAY: 'Igår',

  //material list upload
  UPLOAD_INFORMATION: 'Upload information',
  TYPE_OF_UPLOAD: 'Type of upload',
  SELECT_TYPE: 'Select type',
  MATERIAL_LIST: 'Material list',

  //graphQl error
  BAD_REQUEST: 'Bad request',
  DATA_UPLOADED_SUCCESSFULLY: 'Data uploaded successfully',
  ERROR_IN_UPLOADING_DATA: 'Error in uploading data',
  GENERIC_ERROR_MSG: 'Något gick fel',

  //Invoice economy report
  ECONOMY_INVOICED: 'Ekonomirapport över fakturerade beställningar, per behandlare',

  NO_TRANSPORT_SERVICES_ON_THIS_DAY: 'Inga transporter på denna dag!',
  MANDATORY_COMMENTS: 'Mandatory comments',
  MANDATORY_COMMENTS_DESCRIPTION:
    'If the setting is enable the comment field will be mandatory when creating new and resending orders.',
  REASON_FOR_EXCEPTION_DESCRIPTION:
    'If the setting is enable the system will require the user to select a reason of exception when selecting labs.',
  IMPLANT_STEPS_DESCRIPTION:
    'Only for action-based orders. The available steps for implants.',
  DENTURE_STEPS_DESCRIPTION:
    'Only for action-based orders. The available steps for dentures.',
  PARTIAL_DENTURE_STEPS_DESCRIPTION: 'The available steps for partial dentures.',
  TESTING_STEPS_DESCRIPTION:
    'Only for action based orders. Radio buttons for selecting testing or complete construction.',
  AUTO_APPROVE_CHECK_READY_FOR_INVOICE: 'Auto approve, check for ready for invoice',
  AUTO_APPROVE_CHECK_READY_FOR_INVOICE_DESCRIPTION:
    'If set to true the auto approve feature will also check if the order has the ready for invoice set to true',

  //action list
  ACTION_LISTS: 'Åtgärder',
  SELECT_ACTION_LIST: 'Välj åtgärdslista',
  DEFAULT_ACTION_LIST: 'Standard åtgärdslista',
  CREATE_NEW_ACTION_LIST: 'Ny åtgärdslista',
  NEW_ACTION_LIST: 'Ny åtgärdslista',
  EDIT_ACTION_LIST: 'Ändra åtgärdslista',
  ACTION_LIST_NAME: 'Namn på åtgärdslista',
  ADD_ACTION_LIST: 'Lägg till åtgärdslista',
  UPDATE_ACTION_LIST: 'Uppdatera åtgärdslista',
  ACTION_LIST_CREATED: 'Åtgärdslista skapad',
  ACTION_LIST_UPDATED: 'Åtgärdslista uppdaterad',
  ERROR_IN_CREATING_ACTION_LIST: 'Fel när åtgärdslista skapades',
  ERROR_IN_UPDATING_ACTION_LIST: 'Fel när åtgärdslista uppdaterades',
  DEFAULT_ACTION_LIST_SAVE_SUCCESSFULLY: 'Standard åtgärdslista sparad',
  ERROR_IN_DEFAULT_ACTION_LIST: 'Fel i standard åtgärdslista',
  CATEGORIES: 'Kategorier',
  ADD_CATEGORY: 'Lägg till kategori',
  NEW_CATEGORY: 'Ny kategori',
  EDIT_CATEGORY: 'Ändra kategori',
  CATEGORY: 'Kategori',
  UPDATE_CATEGORY: 'Uppdatera kategori',
  ACTION_CATEGORY_NAME: 'Kategorinamn',
  CATEGORY_CREATED: 'Kategori skapad',
  CATEGORY_UPDATED: 'Kategori uppdaterad',
  ADD_NEW_ACTION: 'Lägg till åtgärd',
  NEW_ACTION: 'Ny åtgärd',
  EDIT_ACTION: 'Ändra åtgärd',
  ACTION_CODE: 'Åtgärdskod',
  INACTIVE_ACTION: 'Inaktiv åtgärd',
  UPDATE_ACTION: 'Uppdatera åtgärd',
  ACTION_UPDATED: 'Åtgärd uppdaterad',
  ACTION_CREATED: 'Åtgärd skapad',
  ALL_CATEGORIES: 'Alla kategorier',
  ASSOCIATED_ACTION_LIST: 'Associerad åtgärdslista',
  NO_ACTION_LIST_SELECTED: 'Ingen åtgärdslista vald',
  SUCCESSFULLY_SAVED_ASSOCIATED_ACTION_LIST: 'Associerad åtgärdslista sparad',
  ERROR_IN_SAVED_ASSOCIATED_ACTION_LIST: 'Fel när åtgärdslista sparades',
  SUCCESSFULLY_UPDATED_ASSOCIATED_ACTION_LIST: 'Uppdatering av åtgärdslista sparad',
  ERROR_IN_UPDATING_ASSOCIATED_ACTION_LIST:
    'Fel när uppdatering av åtgärdslista sparades',
  DISCOUNTED_PRICE_ERROR: 'Rabatterad pris måste vara lägre än baspriset',
  SELECT_ACTION: 'Välj åtgärd',
  ADDED_ACTION: 'Åtgärder',

  //Invoiced orders
  INVOICED_ORDERS: 'Fakturerade ordrar',
  INVOICE_STATUS: 'Status',
  AMOUNT_EXCL_VAT: 'Belopp exkl. moms',

  //pickUp at clinics
  PICK_UP_AT_CLINICS_SAVE_SUCCESSFULLY: 'Upphämtningar på kliniker sparad',
  ERROR_IN_PICK_UP_AT_CLINICS: 'Fel när upphämtningar på kliniker sparades',
  ADDITIONAL_PACKAGES: 'Tilläggspaket',
  RECURRING_PICKUP: 'Återkommande upphämtningar',
  PICKUP_CLINICS_IS_EMPTY:
    'Inga kliniker valda för upphämtning. Välj minst en klinik.',

  //no sample
  NO_SAMPLE: 'Inget avtryck',
  AUTO_APPROVE_DENTHUB: 'Automatiskt godkänd',
  AT_LEAST_ONE_ACTION_REQUIRED: 'Konstruktioner behöver ha minst en åtgärd',

  //3SHAPE
  THREE_SHAPE: '3SHAPE',
  SELECT_SCANS_FOR_UPLOADING: 'Select scans for uploading',
  SCANS_INFO: 'Test Testsson',
  SHAPE_UPLOAD_INFO: 'some info of 3shape upload',
  DOWNLOAD_ALL: 'Ladda ner alla',
  THREE_SHAPE_UPLOADING_CLEAR_FOR: '3shape uploading clear for',
  DOWNLOADING_FROM_3SHAPE_SCANNER:
    'Downloading files from 3D scanner please wait for few minute...',
  FETCHING_SCAN_FROM_3D_SCANNER: 'Fetching scan from 3D scanner',

  PDF: 'Patientspecifikation',
  REGULATION_OF_MEDICAL_PRODUCT:
    'Denna specialanpassade produkt uppfyller de lämpliga kraven om medicintekniska produkter enligt MDR (2017/745). Vid avvikelse kommer detta att nämnas i förklaringen för berört arbete.',
  ORDER_SUMMARY: 'Ordersammanställning',
  CARE_ADVICE: 'Skötselråd',
  REMOVABLE_PRODUCT_ADVICE_HEAD:
    'Avtagbar produkt t ex bettskenor, antiapnéskenor, proteser etc.',
  REMOVABLE_PRODUCT_ADVICE:
    'Borsta med mjuk borste. Använd flytande tvål i ljummet vatten. Lägg produkten i antingen 1 del ättikssprit +2 delar vatten eller, av tillverkaren rekommenderat, protesrengöringsmedel en gång per vecka.',
  FIXED_PRODUCT_ADVICE_HEAD: 'Fast protetik t ex kronor, broar, etc.',
  FIXED_PRODUCT_ADVICE:
    'Borsta med mjuk borste och flourtandkräm. Behandlaren bör ge tips om hur du gör rent mellan tänderna.',

  //new menu
  DENTHUB_USER: 'Användare',
  TECHNICIANS: 'Tekniker',
  USER_SETTINGS: 'Användarinställningar',
  REACT_APP: 'React app',

  //logistics customer
  LOGISTICS_CUSTOMER: 'Logistik kunder',
  CONNECT_CLINICS: 'Kopplade kliniker',
  TRANSPORT_CLINICS: 'Transportkliniker',
  TRANSPORT_CLINIC: 'Transportklinik',
  ZIP_CODE: 'Postadress',
  NEW_TRANSPORT_CLINIC: 'Ny transportklinik',
  ADD_TRANSPORT_CLINIC: 'Skapa transportklink',
  UPDATE_TRANSPORT_CLINIC: 'Uppdatera transportklinik',
  TRANSPORT_CLINIC_ADDED: 'Transportklink har skapats',
  TRANSPORT_CLINIC_UPDATED: 'Transportklink uppdaterad',
  TRANSPORT_CLINIC_DELETED: 'Transportklink borttagen',
  ONLY_NOT_CONNECTED_CLINICS: 'Endast kopplde klinker',

  //statuses
  RECEIVED_BY_LAB: 'Mottaget av labb',
  APPROVED_ORDER: 'Klart/Utlämnat',
  SENT_DENTIST: 'Skickat av tandläkare',
  SENT_LAB: 'Skickat av labb',
  ERROR_IN_STATUS_CHANGE: 'Error in status change',
  RECENTLY_USED_LABS: 'Nyligen använt labb',
  LAST_USED: 'Last used on',
  ALL_STATUSES: 'Alla statusar',
  ALL_SAMPLE_TYPE: 'Alla typer av avtryck',

  //public API secret key setting
  SECRET_KEY: 'Secret key',
  SECRET_KEY_DESCRIPTION: 'Secret key for all public api of organization',

  //white label report
  WHITE_LABEL_USER_LIST: 'White label användare',
  CREATED_DATE: 'Skapad',
  SELECT_GENDER: 'Välj kön',
  GENDER: 'Kön',
  MALE: 'Man',
  FEMALE: 'Kvinna',
  PATIENT_GENDER: 'Patients kön',
  SAVE_DRAFT: 'Spara utkast',
  SAVE_AND_NEXT: 'Spara och nästa',
  COMPLETE_DRAFT: 'Färdigställ utkast',
  PATIENT_GENDER_DESCRIPTION:
    'Activates the function patient gender. This function is only used in actions based orders.',
  COMPLETED_DRAFT: 'Completed draft',
  COMPLETED_DRAFT_DESCRIPTION:
    'Only for action-based orders. Enables user to save order as "completed draft", which can be reviews by dentist before sending the order to lab.',
  DISALLOW_NON_DENTIST: 'Disallow non dentists',
  DISALLOW_NON_DENTIST_DESCRIPTION: 'Disallow non dentists from placing order',

  //image annotation
  CONFIRM_CLOSE: 'Vill du stänga och slänga dina ändringar?',
  ANNOTATION_INSTRUCTIONS:
    'För att rita på bifogade bilder, klicka på "Ändra" brevid bilden.',
  DRAW_NEW_IMAGE: 'Rita ny bild',
  ADD_IMAGE: 'Rita bild',
  FILENAME: 'Filnamn',
  SELECT_IMAGE_BACKGROUND: 'Välj bakgrund',

  //new statuses
  LAB_WORK_STARTED_BUTTON: 'PÅBÖRJA PRODUKTION',
  LAB_WORK_STARTED_DESCRIPTION: 'Enables if the status Lab work started for lab',
  CLINIC_RECEIVED_DELIVERY: 'Leverans mottagen',
  CLINIC_RECEIVED_DELIVERY_BUTTON: 'MOTTAGEN LEVERANS',
  CLINIC_RECEIVED_DELIVERY_DESCRIPTION:
    'Enables if the status Clinic received delivery for dentist',
  AUTO_REMOVE_DRAFT_ORDERS: 'Auto remove draft orders',
  AUTO_REMOVE_DRAFT_ORDERS_DESCRIPTION:
    'The Number of days after which automatic process removes draft orders',

  //API setting
  USER_SYNC: 'User sync',
  USER_SYNC_DESCRIPTION: 'Enable/Disable user sync API.',
  GET_ORDER: 'Get Order',
  GET_ORDER_DESCRIPTION: 'Enable/Disable get order API.',
  GET_REPORT: 'Get report',
  GET_REPORT_DESCRIPTION: 'Enable/Disable get report API.',
  FILE_UPLOAD: 'File upload',
  FILE_UPLOAD_DESCRIPTION: 'Enable/Disable file upload API.',
  DRAFT_SAVED: 'Utkast sparat',

  //report for orders price per dentist
  SUM_OF_ORDERS_PRICE_PER_DENTIST: 'Summa av beställningar, per behandlare',

  // Order details tabs
  ORDER_DETAILS: 'Order',
  ORDER_EVENTS: 'Händelser',
  LAB_INTERNAL: 'Internt',

  PATIENTS: 'Patienter',
  SHOW_PATIENT_HISTORY: 'Visa alla ordrar för denna patient',

  WORKFLOW: 'Arbetsflöde',

  COLLECTIVE: 'Samlingsfaktura - Grupperad per klinik',
  COLLECTIVE_DENTIST: 'Samlingsfaktura - Grupperad per tandläkare och klinik',

  // New invoice status:
  INVOICE_STATUS_TEMP: 'Skapad',
  INVOICE_STATUS_TEMP_READY: 'Klar för fakturering',
  INVOICE_STATUS_CREATED: 'Skapad',
  INVOICE_STATUS_PROCESSING: 'Bearbetar',
  INVOICE_STATUS_SENT: 'Skickad',
  INVOICE_STATUS_ERROR: 'Fel',
  PARTICIPANTS: 'Deltagare',
  PARTICIPANT_INFO: 'Alla deltagare får notifieringar avseende denna beställning.',
  ADD_PARTICIPANT: 'LÄGG TILL DELTAGARE',
  INVOICING_TYPE: 'Fakturatyp',
  INVOICING_TYPE_DESCRIPTION: 'Fakturatyp',
  INVOICING_DELIVERY_DESCRIPTION: 'Metod för fakturaleverans',
  CERTIFICATION: 'Attestering',
  CERTIFICATION_DESCRIPTION: 'Faktura attest funktionen',
  CERTIFIED_BY: 'Attesterad av',
  CERTIFY_ORDER: 'Attestera order',
  CERTIFY: 'Attestera',
  CONFIRM_MESSAGE: 'Är du säker?',
  CONFIRM_TITLE: 'Bekräfta',
  CONFIRM_CERTIFY: 'Ja, priset från labbet är korekt och jag attesterar fakturan.',
  PRODUCTION_FINISHED: 'Produktion färdigställd',
  PRODUCTION_FINISHED_ACTION: 'Färdigställ produktion',
  PRODUCTION_NOT_FINISHED: 'Produktion pågår',
  PRODUCTION_NOT_FINISHED_ACTION: 'Återstarta produktion',
  CONFIRM_PRODUCTION_FINISHED:
    'Är du säker på att du vill flagga denna order som färdigställd?',
  CONFIRM_PRODUCTION_NOT_FINISHED:
    'Är du säker på att du vill återstarta produktionen och sätta denna order till "Produktion pågår"?',
  PRODUCTION_FINISHED_NOTE:
    'OBS: Färdigställd produktion innebär inte att ordern kommer skickas till kliniken eller att ordern sätts till "Klar för fakturering". Vänligen glöm inte att använd "Skicka till klinik" funktionen vid ett senare tillfälle.',
  PRODUCTION_NOT_FINISHED_NOTE:
    'Denna order är redan flaggad som färdigställd. Denna funktion kommer att sätta ordern tillbaka till "Produktion pågår" och rensa eventuell "Klar för fakturering".',
  ERROR_IN_PRODUCTION_ORDER: 'Fel i produktion order',
  FINISHED: 'Färdigställd',
  NOT_FINISHED: 'Inte färdigställd',
  ORDER_HAS_BEEN_INVOICED: 'Denna order har fakturerats',
  INVOICING_DELIVERY: 'Fakturaleverans',
  DELIVERY_NOTE: 'Följesedel',
  LAB_ORDER_INFO:
    'Denna order har registrerats manuellt av labb. Du kan därför inte ändra denna order.',
  DO_NOT_INVOICE: 'Fakturera ej denna order',
  ERROR_IN_DO_NOT_INVOICE: 'Något gick fel',
  DO_NOT_INVOICE_SUCCESSFULLY: 'Ordern kommer ej att faktureras',
  REACTIVATE_INVOICE: 'Återaktivera fakturering',
  INVOICE_STATE: 'Fakturering',
  NOT_READY: 'Ej klar för fakturering',
  SAMPLES_PER_DENTIST: 'Avtryck, per tandläkare',
  PHYSICAL_SAMPLES: 'Fysiskt avtryck',
  DIGITAL_SAMPLES: 'Digitalt avtryck',
  NO_SAMPLES: 'Inget avtryck',
  DOWNLOAD_DOCUMENTS: 'Dokument & utskrift',
  INVOICE: 'Faktura',
  DELIVERY_NOTE_DOWNLOADED_SUCCESSFULLY: 'Följesedel laddas ner',
  SEND_TO_STEP: 'Ändra steg',
  NO_STEP: 'Inget steg',
  WORKFLOW_LOG: 'Logg för arbetsflöde',
  PAUSE_STEP: 'Pausa i detta steg',
  UN_PAUSE_STEP: 'Forsätt i detta steg',
  ORDER_IS_PAUSED: 'Order är pausad',
  TRANSPORT_TRACKING_LINK: 'Länk, spåra försändelse',
  OPEN_TRACKING_PAGE: 'Öppna sidan',
  ERROR_IN_TRANSPORT_TRACKING_LINK: 'Fel när länken sparades',
  SELECTED_PREVIOUS_DATE: 'Avtrycket togs emot',
  ZERO_PRICE_ORDER: 'Kan ej fakaturera en order som saknar pris (0 kr).',
  DELIVERY_WARNING: 'Ej samma plats för upphämtning och lämning.',
  FORTNOX: 'Fortnox',
  FORTNOX_DESCRIPTION: 'Aktivera/inaktivera Fortnox integration.',
  VISMA: 'Visma',
  VISMA_DESCRIPTION: 'Aktivera/inaktivera Visma integration.',
  FORTNOX_BOOKKEEPING: 'Fortnox Bookkeeping',
  FORTNOX_BOOKKEEPING_DESCRIPTION: 'Enable/Disable the Fortnox bookkeeping feature.',
  FORTNOX_CUSTOMER_NUMBER: 'Fortnox kundnummer',
  FORTNOX_CUSTOMER_NUMBER_DESCRIPTION: 'Labbets Fortnox kundnummer.',
  FORTNOX_VAT_ACCOUNT_CODES: 'Fortnox VAT account codes',
  FORTNOX_VAT_ACCOUNT_CODES_DESCRIPTION:
    'Lab Fortnox account number codes per VAT type.',
  IN_HOUSE_TRANSPORT: 'Aktivera inhouse transport',
  SCAN_CASES: 'Skanner-ordrar',
  SCAN_CASES_DESCRIPTION: 'Aktivera/inaktivera scanner-ordrar',
  SCAN_CASE_EMAIL_WHITE_LIST: 'Scan case email white list for 3Shape',
  SCAN_CASE_EMAIL_WHITE_LIST_DESCRIPTION:
    'Enable/Disable the Scan case email white list feature.',
  THREE_SHAPE_INTEGRATION: '3Shape Integration',
  THREE_SHAPE_INTEGRATION_DESCRIPTION: 'Aktivera/inaktivera 3Shape integration.',
  MEDIT_INTEGRATION: 'Medit Integration',
  MEDIT_INTEGRATION_DESCRIPTION: 'Aktivera/inaktivera Medit integration.',
  THREE_SHAPE_CLIENT_ID: '3Shape Client ID',
  THREE_SHAPE_CLIENT_ID_DESCRIPTION: 'Client ID for 3shape.',
  SYNC_FROM_DATE: 'Sync scan cases after date',
  SYNC_FROM_DATE_DESCRIPTION:
    'Controls the date from which the Scan cases will be synced. Format: YYYY-MM-DD OR MM/DD/YYYY . Example input: 2023-06-27, 06/27/2023',
  THREE_SHAPE_DCM_FILE_DOWNLOAD: '3Shape DCM file download',
  THREE_SHAPE_DCM_FILE_DOWNLOAD_DESCRIPTION:
    'Enable/Disable 3Shape DCM file download.',
  THREE_SHAPE_PLY_FILE_DOWNLOAD: 'PLY file download',
  THREE_SHAPE_PLY_FILE_DOWNLOAD_DESCRIPTION:
    'Enable/Disable 3Shape/Medit PLY file download.',
  MEDIT_OBJ_FILE_DOWNLOAD: 'Medit OBJ file download',
  MEDIT_OBJ_FILE_DOWNLOAD_DESCRIPTION:
    'Aktivera/inaktivera Medit OBJ file download.',
  CHECK_ABANDONED_CASES: 'Check abandoned scan cases',
  CHECK_ABANDONED_CASES_DESCRIPTION:
    'Enable/Disable 3Shape check abandoned scan cases.',
  CHAT_TEMPLATE: 'Chat template',
  CHAT_TEMPLATE_DESCRIPTION: 'Enable/Disable chat template feature.',
  USE_CALENDAR_DELIVERY_DAYS: 'Use calendar delivery days',
  USE_CALENDAR_DELIVERY_DAYS_DESCRIPTION:
    'Enable/Disable use calendar delivery days.',
  LAB_MILLING: 'Lab Milling',
  LAB_MILLING_DESCRIPTION: 'Enable/Disable the lab milling feature.',
  EDIT_USER_TITLE: 'Edit User Title',
  EDIT_USER_TITLE_DESCRIPTION: 'Enable/Disable the edit user title feature.',
  TRANSPORT_PROVIDERS: 'Transport-leverantörer',
  TRANSPORT_PROVIDERS_DESCRIPTION:
    'te.x.: [{"externalId": "123", "code": "abc", "channel": "NSHIFT"}]',
  SHOW_TEMPLATE_DIALOG: 'Message templates',
  CREATE_INVOICE: 'Skapa faktura',
  IN_HOUSE_TRANSPORT_DESCRIPTION: 'Aktiver/Inaktivera labb inhouse transport.',
  HAS_THE_CONSTRUCTION_BEEN_CEMENTED: 'Har konstruktionen blivigt cementerad?',
  NOT_CEMENTED: 'Ej cementerad',
  UNKNOWN: 'Cementering okänd',
  INVOICE_IN_ERROR_STATE: 'Fakturan har ett fel',
  INVOICE_NUMBER_FORTNOX_ENABLED: 'Fortnox skapar automatiskt fakturanummer.',
  CREATE_WARRANTY_ORDER: 'Skapa garantiärende',
  LINKED_ORDERS: 'Länkade ordrar',
  WARRANTY_ORDER_OF: 'Garantiärende för',
  MODIFY_ORDER_INFO:
    'Ordern kan ej ändras om den är färdigställd och/eller klar för fakturering.',
  DEVIATIONS: 'Avvikelser',
  DISABLED_WHITE_LABEL:
    'Labbet är ej kopplat eller har valt att avaktivera sin white label. Vänligen kontakta support.',
  USE_LAB_LOGIN:
    'Du behöver använda den inloggningssidan du fått från labbet för detta konto. Om du saknar länken till denna inloggningssida är du välkommen att kontakta labbet eller Denthub på support@denthub.com.',
  INTERNAL: 'Intern',
  EXTERNAL: 'Extern',
  DEVIATION_ACTION: 'Åtgärd',
  INTERNAL_DELIVERY_DATE: 'Deadline för produktion',
  DATE_UNKNOWN: 'Inget datum',
  CHAT_DISABLED_FOR_DENTIST_INFO:
    'Behandlaren kan ej svara på meddelanden när ordern är färdigställd och/eller klar för fakturering.',
  ORDER_INTERACTION_DISABLED_INFO:
    'Produktionen är färdiställed och/eller ordern är fakturerad. Interaktion med denna order är därför inaktiverad. För vidare uppföljning vänligen skapa en ny order eller ett garantiärende.',
  CLOSE: 'Stäng',
  ERROR_TITLE: 'Fel inloggningssida',
  INTRAORAL_SCAN: 'Intraorala skanningar',
  ATTACH_INTRAORAL_SCAN: 'Bifoga intraoral skanning',
  THREE_SHAPE_COMMENT: '3Shape kommentar',
  DESIGN_SETTINGS: 'Design',
  THREE_SHAPE_CASE: '3Shape case',
  ATTACH_THREE_SHAPE_FILES: 'Attach 3Shape files',
  UPDATED: 'Updated',
  SHOW_3SHAPE_CASE: 'Show 3Shape case',
  LINKTYPECONNECTOR6: 'Bro',
  VENEER: 'Tandfasad',
  INLAY: 'Tandfyllning - Inlay',
  ONLAY: 'Tandfyllning - Onlay',
  ABUTMENTSCREWRETAINEDCROWN: 'Skruvförankrad krona',
  ABUTMENT: 'Förankring',
  CROWNPONTIC: 'Pontic',
  DIGITALMODELPREPUNSECTIONED: '??DIGITALMODELPREPUNSECTIONED??',
  COPING: '??COPING??',
  CLASP: 'Tandklammer',
  ANATOMICALPONTIC: '??ANATOMICALPONTIC??',
  LOWERJAWSCAN: 'Underkäke',
  UPPERJAWSCAN: 'Överkäke',
  BITESCAN: 'Bett 1',
  BITESCAN2: 'Bett 2',
  UPPERIMPLANTSCAN: 'Implantat, över',
  LOWERIMPLANTSCAN: 'Implantat, under',
  MANUFACTURER: 'Tillverkare',
  CONNECTION: 'Koppling',
  DIAMETER: 'Diameter',
  LENGTH: 'Längd',
  LINK: 'Länk',
  ANY_SHADE: 'Ej specifierad',
  DCM_FILES_TITLE: 'DCM-filer (3Shape-format)',
  PLY_FILES_TITLE: 'PLY-filer',
  STL_FILES_TITLE: 'STL-filer',
  SHOW_CASE_AND_DOWNLOAD_FILES: 'Visa case och ladda ner filer',
  DOWNLOAD_3SHAPE_FORM: 'Ladda ner 3Shape formulär',
  OTHER_FILES_TITLE: 'Övriga filer',
  SCAN_FILE_COUNT: 'skanningar',
  ALL_FILES_COUNT: 'filer totalt',
  SCAN_CASE: 'Skanner-case',
  ATTACHED_SCANS: 'Bifogade skanningar',
  DENTIST_NOTE: 'Egna anteckningar',
  NOTE: 'Anteckningar',
  ACTIVE_ACTION_SET: 'Active action set',
  ADD_ACTION_SET: 'Add action set',
  ATTACHED_IMAGE: 'Bifogad bild från förare',
  SHOW_IMAGE: 'Visa bild',
  ANNOTATE: 'Annotate',
  DESTINATION_ADDRESS_LABEL: 'Leverans-etikett',
  PATIENT_LABEL: 'Patient-etikett',
  DISCOUNTED_PRICE_INFO:
    'When modifying the order any price deductions/discounts made on this order will reset to the original price.',
  SAML_CONFIG: 'SAML Configuration',
  SAML_CONFIG_DESCRIPTION: 'Configure SAML IDP for this organization.',
  SAML_ENTITY_ID: 'SAML Entity ID',
  SAML_ENTITY_ID_DESCRIPTION: 'SAML Identify Provider Entity ID.',
  SHOW_ORDER_CREATOR_IN_PER_ORDER_INVOICE: 'Show order creator in per order invoice',
  SHOW_ORDER_CREATOR_IN_PER_ORDER_INVOICE_DESCRIPTION:
    'Enable/Disable show creator of an order in the per order invoice feature.',
  PRINT_ORDER_SUMMARY: 'Ordersammanställning (A4)',
  COPY_ORDER: 'Copy order',
  CUSTOM_EMAIL: 'Custom email',
  ORDER_FORM_AI: 'Ordersedel AI',
  ORDER_FORM_AI_DESCRIPTION: 'Aktivera/inaktivera ordersedel AI.',
  ADD_CONNECTED_DENTIST: 'Add Connected Dentist',
  ADD_CONNECTED_DENTIST_DESCRIPTION: 'Enable/Disable Add connected Dentist for Lab.',
  EDIT_ACTION_PRICE_LIST: 'Edit Action Price List',
  EDIT_ACTION_PRICE_LIST_DESCRIPTION: 'Enable/Disable Edit action price list for Lab.'
};
